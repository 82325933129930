import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import api from '../../api/Api';
import { Asset } from '@memnon.orka/orka-sdk';

import styles from './AssetSelect.module.scss';
import formStyles from '../../form/form.module.scss';

type Props = {
  onChange: (value?: Asset) => void;
  defaultValue?: Asset;
  value?: Asset;
  disabled?: boolean;
  isInvalid?: boolean;
  isClearable?: boolean;
  acceptedTypes?: string[];
  siteId?: string;
  parentId?: string;
  parentType?: 'Asset' | 'ProductionUnit' | 'ProductionLine';
  showAssociated?: boolean;
};

type Option = {
  value: string;
  label: any;
  _original: Asset;
};

const AssetSelect: FC<Props> = ({
  onChange,
  value,
  disabled,
  isInvalid,
  acceptedTypes,
  isClearable,
  siteId,
  showAssociated,
  parentId,
  parentType,
  defaultValue
}) => {
  const { t } = useTranslation('i18n');

  const [selected, setSelected] = useState<Option | Option[]>();

  const composeOption = (asset: Asset): Option => {
    let associated = !!asset.isAssigned;
    if (parentType && parentId) {
      associated = associated && (asset as any)[`assignedTo${parentType}`]?.id !== parentId;
    }

    const part2 = [
      asset.characteristic?.brand?.trim(),
      asset.characteristic?.model?.trim(),
      asset.characteristic?.serial?.trim()
    ]
      .filter((t) => !!t)
      .join(' - ');

    return {
      _original: asset,
      value: asset.id,
      label: (
        <span
          className={
            asset.id === value?.id ? styles.selected : associated ? styles.associated : styles.selectable
          }
        >
          <span className={styles.id}>{asset.name || asset.reference?.productionId || ''}</span>
          {part2 && <span> | {part2}</span>}
          {associated ? ` (${t('asset.associated').toUpperCase()})` : ''}
        </span>
      )
    };
  };

  useEffect(() => {
    setSelected(!value ? undefined : composeOption(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, t]);

  const handleOnChange = (items?: Option) => {
    onChange(items ? items._original : undefined);
  };

  const loadData = async (search?: string) => {
    const request: Record<string, string | number | boolean> = search
      ? {
          size: 200,
          search: `startsWith:${search}`
        }
      : {
          size: 200
        };
    if (acceptedTypes?.length) {
      request.type = `in:${acceptedTypes.join(';')}`;
    }
    if (siteId) {
      request.siteId = `in:${siteId}`;
    }
    if (!showAssociated) {
      request.isAssigned = `equals:false`;
    }

    const assets = await api.assets.listAssets(request);
    let data = assets.data.items.map(composeOption);

    //Force to add defaultValue as selection if not present and no search
    if (!search && defaultValue && !assets.data.items.some((a) => a.id === defaultValue.id)) {
      data = [composeOption(defaultValue), ...data];
    }

    return data;
  };
  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <AsyncSelect
        key={`select-asset-${module}-${siteId}-${showAssociated}`}
        defaultOptions
        loadOptions={loadData}
        className={isInvalid ? formStyles.invalid : formStyles.valid}
        onChange={handleOnChange as any}
        isMulti={false}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
        isClearable={isClearable || false}
      />
    </>
  );
};

export default AssetSelect;
