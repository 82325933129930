import React, { FC, useState, useEffect } from 'react';

import Select from 'react-select';

import { Form } from 'react-bootstrap';
import styles from '../form/form.module.scss';
import { useTranslation } from 'react-i18next';

export const PRONOUNS = ['he/him', 'she/her', 'they/them'];

type Props = {
  disabled?: boolean;
  isInvalid?: boolean;
  onChange: (value?: string) => void;
  value?: string;
};

type Option = {
  value: string;
  label: string;
};

const MemberPronounsSelect: FC<Props> = ({ onChange, value, disabled, isInvalid }) => {
  const { t } = useTranslation('i18n');
  const [options] = useState(
    PRONOUNS.map((pronouns) => ({
      value: pronouns,
      label: t(`member.pronounsValues.${pronouns}`)
    }))
  );
  const [selected, setSelected] = useState<Option | Option[]>();

  useEffect(() => {
    setSelected(!value ? undefined : options.find((pronouns) => pronouns.value === value));
  }, [value, options]);

  const handleOnChange = (items?: Option) => {
    onChange(items ? items.value : undefined);
  };

  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <Select
        className={isInvalid ? styles.invalid : styles.valid}
        options={options}
        onChange={handleOnChange as any}
        isMulti={false}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
      />
    </>
  );
};

export default MemberPronounsSelect;
