import { AssetConfig } from "../@types/webapp";

export const ASSET_CONFIG: AssetConfig[] = [
    {
        type: 'videoPlayer',
        modules: [
            'isrPort',
            'firewireDeviceId',
            'ethernetMacAddress',
            'head'
        ],
        remoteControlProtocols: ['RS422'],
        applicationProtocols: ['FTP', 'SMB', 'HTTP'],
        categories: ['digital', 'film', 'vcr', 'vtr']
    },
    {
        type: 'cleaner',
        modules: [],
        remoteControlProtocols: ["RS422"],
        categories: ["cassette"]
    },
    {
        type: 'robot',
        modules: [],
        remoteControlProtocols: ["flexicart", "mirage"],
        categories: ["flexicart", "mirage"]
    },
    {
        type: 'audioPlayer',
        modules: ['head'],
        remoteControlProtocols: ["DAT", "STUDER"],
        categories: ["cassette", "digital", "disk", 'openReel',]
    },
    {
        type: 'remote',
        modules: ['ethernetMacAddress', 'ports'],
        categories: ['ethernetToParallel', "ethernetToSerial"]
    },
    {
        type: 'workStation',
        modules: ['ethernetMacAddress'],
        categories: ["any", "audio", "video"]
    },
    {
        type: 'videoEncoder',
        modules: ['ingestChannels'],
        categories: ["memnonIngestService", "opencube"]
    },
    {
        type: 'preAmp',
        modules: [],
        categories: ["phono"]
    },
    {
        type: 'timeBaseCorrector',
        modules: [],
        categories: ["video"]
    }
];
