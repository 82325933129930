import React, { useState } from 'react';
import { Navbar } from 'react-bootstrap';
import styles from './Footer.module.scss';

const Footer = () => {
  const [stage] = useState(process.env.REACT_APP_STAGE === 'prd' ? '' : process.env.REACT_APP_STAGE);
  return (
    <Navbar bg="light" expand="lg" fixed="bottom" style={{ zIndex: 1400 }}>
      <div className={styles.footer}>
        <span>
          Version {process.env.REACT_APP_VERSION}-{process.env.REACT_APP_BUILD_NUMBER}
        </span>
        {stage && <span>[{stage}]</span>}
      </div>
    </Navbar>
  );
};

export default Footer;
