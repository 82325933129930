import React from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from '../datagrid/DataGrid';
import api from '../api/Api';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Can, useAbility } from '@casl/react';

import { Link, Outlet } from 'react-router-dom';
import { BodyApi, DataGridColumn } from '../@types/webapp';
import { getProductionUnitPath } from '../navigation/AppRoutes';
import AbilityContext from '../context/AbilityContext';
import { Resource } from '../tools/resourceTools';
import { DEFAULT_TEXT_DELAY } from '../datagrid/datagridTools';
import ProductionUnitTypeSelectFilter from './ProductionUnitTypeSelectFilter';
import { ProductionUnit } from '@memnon.orka/orka-sdk';
import SiteLabel from '../components/Production/SiteLabel';
import TypeLabel from '../components/Production/TypeLabel';
import { convertApiResponseToSelectOptions } from '../components/Select/selectTools';
import ResourceSelectFilter from '../components/Select/ResourceSelectFilter';
import styles from '../datagrid/DataGrid.module.scss';

const ProductionUnitList = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);
  const location = useLocation();

  const navigate = useNavigate();

  const columns: DataGridColumn[] = [
    {
      name: 'name',
      sortable: true,
      header: t('production-unit.name'),
      render: ({ data }: any) => {
        return (
          <Link to={getProductionUnitPath({ mode: 'get', productionUnitId: data.id })}>{data.name}</Link>
        );
      },
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY,
      defaultFlex: 1,
      minWidth: 100
    },
    {
      name: 'siteId',
      sortable: true,
      header: t('production-unit.site'),
      render: ({ data }: any) => {
        return <SiteLabel siteId={data.siteId} />;
      },
      filterProps: {
        type: 'select',
        name: 'siteId',
        value: location.state?.siteId,
        _source: location.state?.siteId
          ? { value: location.state.siteId, label: location.state.siteId }
          : undefined
      },
      filterEditor: ResourceSelectFilter,
      filterEditorProps: {
        isMulti: true,
        loadData: async (search?: string) =>
          convertApiResponseToSelectOptions({
            response: await api.sites.listSites(
              search
                ? {
                    size: 200,
                    name: `contains:${search}`
                  }
                : {
                    size: 200
                  }
            ),
            idPath: 'id',
            labelPath: 'id'
          })
      },
      defaultWidth: 200
    },
    {
      name: 'type',
      header: t('production-unit.type'),
      sortable: true,
      render: ({ data: { type } }: { data: ProductionUnit }) => {
        return <TypeLabel type={type} />;
      },
      filterProps: {
        type: 'select',
        name: 'type',
        value: location.state?.type
      },
      filterEditor: ProductionUnitTypeSelectFilter,
      filterEditorProps: {
        isMulti: true
      },
      defaultWidth: 200
    },
    {
      name: 'lineCount',
      header: t('production-unit.lineCount'),
      sortable: false,
      render: ({ data: { lineCount } }: { data: ProductionUnit }) => {
        return <div className={styles.number}>{lineCount}</div>;
      },
      defaultWidth: 80
    }
  ];

  const loadData = async (body: BodyApi) => {
    return api.production.listProductionUnits({ ...body, includeLineCount: true });
  };

  return (
    <>
      <DataGrid
        title={t(`production-unit.title`)}
        columns={columns}
        loadData={loadData}
        resourceName={Resource.PRODUCTION_UNIT}
        RightActions={[
          <Can I="post" a={Resource.PRODUCTION_UNIT} ability={ability} key="createButton">
            <Button
              key="create"
              onClick={() => {
                navigate(getProductionUnitPath({ mode: 'create' }));
              }}
            >
              {t('production-unit.create')}
            </Button>
          </Can>
        ]}
      />
      <Outlet></Outlet>
    </>
  );
};

export default ProductionUnitList;
