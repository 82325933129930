import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { confirmable } from 'react-confirm';
import styles from './ConfirmModal.module.scss';
import { ButtonVariant } from 'react-bootstrap/esm/types';

export type ConfirmModalOptions = {
  title?: string;
  btnPrimaryLabel?: string | null;
  btnPrimaryVariant?: ButtonVariant;
  btnSecondaryLabel?: string | null;
  hideSecondaryButton?: boolean;
};

type Props = {
  show: boolean;
  proceed: (value: boolean) => void;
  confirmation: string | JSX.Element;
  options?: ConfirmModalOptions;
};

const ConfirmModal: React.FC<Props> = ({
  show,
  proceed,
  confirmation,
  options: { title, btnPrimaryVariant, btnPrimaryLabel, btnSecondaryLabel, hideSecondaryButton } = {}
}) => {
  const { t } = useTranslation('i18n');
  return (
    <Modal backdropClassName={styles.modalBackdrop} centered show={show} onHide={() => proceed(false)}>
      <Modal.Header closeButton>{title && <Modal.Title>{title}</Modal.Title>}</Modal.Header>
      <Modal.Body>{confirmation}</Modal.Body>
      <Modal.Footer>
        {!hideSecondaryButton && (
          <Button variant="secondary" onClick={() => proceed(false)}>
            {btnSecondaryLabel || t('action.cancel')}
          </Button>
        )}

        <Button variant={btnPrimaryVariant || 'primary'} onClick={() => proceed(true)}>
          {btnPrimaryLabel || t('action.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//@ts-ignore
export default confirmable(ConfirmModal);
