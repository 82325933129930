import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../dialog/DefaultCrudDialog';
import RoleForm from './MemberForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getMemberPath } from '../navigation/AppRoutes';
import { Member, MemberPost, MemberPut } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import AppStateContext from '../context/AppStateContext';
import {
  ADD_RESOURCE,
  DELETE_RESOURCE,
  DispatchResourceAction,
  UPDATE_RESOURCE
} from '../context/AppStateReducer';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';

const MemberDialog = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();

  const ref = useRef();

  const { memberId } = useParams();

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [member, setMember] = useState<Partial<Member>>();
  const [existingMember, setExistingMember] = useState<Member>();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    navigate(getMemberPath({ mode: 'list' }));
  };

  const handleLoadMember = async (memberId: string) => {
    setLoading(true);

    try {
      const { data } = await api.members.getMember(memberId);
      setExistingMember(data);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (memberId) {
      handleLoadMember(memberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  const handleSave = async () => {
    if (!(ref?.current as any)?.isValid()) {
      return false;
    }

    setSaving(true);
    try {
      if (memberId) {
        const updates: MemberPut = {
          firstName: member?.firstName,
          lastName: member?.lastName,
          country: member?.country,
          roles: member?.roles,
          pronouns: member?.pronouns,
          phone: member?.phone,
          language: member?.language
        };
        const { data: memberUpdated } = await api.members.updateMember(memberId, updates);
        dispatch({
          payload: memberUpdated,
          resourceName: Resource.MEMBER,
          type: UPDATE_RESOURCE
        } as DispatchResourceAction);
        toast.success(
          t(`member.success.update`, { name: `${memberUpdated.lastName} ${memberUpdated.firstName}` })
        );
      } else {
        const { data: memberCreated } = await api.members.createMember(member as MemberPost);
        dispatch({
          payload: memberCreated,
          resourceName: Resource.MEMBER,
          type: ADD_RESOURCE
        } as DispatchResourceAction);
        toast.success(
          t(`member.success.create`, { name: `${memberCreated.lastName} ${memberCreated.firstName}` })
        );
      }
      setSaving(false);
      return true;
    } catch (error: any) {
      if (
        error?.error?.error?.details?.some(
          (detail: any) => detail.message === 'Not unique' && detail.path?.some((p: string) => p === 'email')
        )
      ) {
        toast.error(t('member.error.notUnique', { email: member?.email }), {
          autoClose: false
        });
      } else if (
        error?.error?.error?.details?.some(
          (detail: any) =>
            detail.message === '"email" must be a valid email' &&
            detail.path?.some((p: string) => p === 'email')
        )
      ) {
        toast.error(t('member.error.email', { email: member?.email }), {
          autoClose: false
        });
      } else {
        toast.error(t(getErrorKey(error)), {
          autoClose: false
        });
      }
      setSaving(false);
      return false;
    }
  };
  const handleDelete = async () => {
    try {
      if (memberId) {
        await api.members.deleteMember(memberId);
        dispatch({
          payload: {
            id: memberId
          },
          resourceName: Resource.MEMBER,
          type: DELETE_RESOURCE
        } as DispatchResourceAction);
        toast.success(t(`member.success.delete`));
      }
      setSaving(false);
      return true;
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return false;
    }
  };

  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: 'post',
        resource: Resource.MEMBER
      }}
      title={memberId ? t(`member.update`) : t(`member.create`)}
      closeLabel={t('action.cancel')}
      saveLabel={memberId ? t(`action.update`) : t(`action.create`)}
      onClose={handleClose}
      saving={saving}
      onDelete={memberId && member ? handleDelete : undefined}
      onSave={handleSave}
      deleteConfirmLabel={
        <div
          dangerouslySetInnerHTML={{
            __html: t('member.confirmDelete', { name: `${member?.lastName} ${member?.firstName}` })
          }}
        />
      }
      loading={loading}
    >
      {existingMember ? (
        <RoleForm ref={ref} mode="update" member={existingMember} onChange={setMember} disabled={saving} />
      ) : (
        <RoleForm ref={ref} mode="create" onChange={setMember} disabled={saving} />
      )}
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default MemberDialog;
