export enum Resource {
  ASSET = 'asset',
  MEMBER = 'member',
  PERMISSION_DEFINITION = 'permission-definition',
  PERMISSION_SET = 'permission-set',
  PRODUCTION_LINE = 'production-line',
  PRODUCTION_UNIT = 'production-unit',
  PRODUCTION_UNIT_LINES = 'production-unit-lines',
  ROLE = 'role',
  SITE = 'site'
}
