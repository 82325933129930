export const generateSiteId = (countryCode?: string, increment?: string) => {
  if (!countryCode || !increment) {
    return ""
  }
  return `${countryCode}-${increment}`;
};

export const extractCountryFromSiteId = (siteId: string) => {
  const [country, increment] = siteId.split('-');
  return { country, increment };
};
