import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Can, useAbility } from '@casl/react';

import AbilityContext from '../context/AbilityContext';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import AppStateContext from '../context/AppStateContext';
import { Resource } from '../tools/resourceTools';
import { ProductionUnit } from '@memnon.orka/orka-sdk';
import api from '../api/Api';
import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import LoadingPage from '../components/Loading/LoadingPage';
import NotAllowed from '../components/Access/NotAllowed';
import ProductionUnitBreadcrumb from '../components/Production/ProductionUnitBreadcrumb';
import styles from './ProductionUnitPage.module.scss';

import ProductionUnitDetails from '../components/Production/ProductionUnitDetails';
import AssetAssociated from '../components/Production/AssetAssociated';
import Page from '../components/Page/Page';
import { getProductionUnitPath } from '../navigation/AppRoutes';
import { subject } from '@casl/ability';
import ProductionLinesLinked from '../components/Production/ProductionLinesLinked';

const resourceName = Resource.PRODUCTION_UNIT;

const ProductionUnitPage = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { state } = useContext(AppStateContext);
  const [productionUnit, setProductionUnit] = useState<ProductionUnit>();

  const [canUpdate, setCanUpdate] = useState(false);

  const { productionUnitId } = useParams();

  const loadProductionUnit = async (productionUnitId: string) => {
    setLoading(true);
    try {
      const response = await api.production.getProductionUnit(productionUnitId);
      setProductionUnit(response.data);
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  const handleUpdateDetails = () => {
    navigate(
      getProductionUnitPath({
        mode: 'update',
        productionUnitId: productionUnit?.id || '',
        module: 'details'
      })
    );
  };

  const handleUpdateAsset = (type: 'workStation' | 'robot') => {
    navigate(
      getProductionUnitPath({
        mode: 'update',
        productionUnitId: productionUnit?.id || '',
        module: type,
        siteId: productionUnit?.siteId || ''
      })
    );
  };
  const handleUpdateRobot = () => {
    handleUpdateAsset('robot');
  };
  const handleUpdateWorkStation = () => {
    handleUpdateAsset('workStation');
  };

  useEffect(() => {
    let productionUnitInMemory = state.resources[Resource.PRODUCTION_UNIT]?.items?.find(
      (i) => i.id === productionUnitId
    ) as ProductionUnit | undefined;
    if (productionUnitInMemory) {
      setProductionUnit(productionUnitInMemory);
    } else if (productionUnitId && productionUnitId !== productionUnit?.id) {
      loadProductionUnit(productionUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionUnitId, state.resources]);

  useEffect(() => {
    setCanUpdate(!!productionUnit && ability.can('put', subject(Resource.PRODUCTION_UNIT, productionUnit)));
  }, [ability, productionUnit]);

  return (
    <Page>
      <Can not I="get" a={resourceName} ability={ability}>
        <NotAllowed />
      </Can>
      <Can I="get" a={resourceName} ability={ability}>
        {loading || !productionUnit ? (
          <LoadingPage />
        ) : (
          <div className={styles.root}>
            <ProductionUnitBreadcrumb productionUnit={productionUnit} />
            <ProductionUnitDetails
              className={styles.item}
              productionUnit={productionUnit}
              handleUpdateClicked={canUpdate ? handleUpdateDetails : undefined}
            />
            <AssetAssociated
              className={styles.item}
              assetRef={productionUnit.workStation}
              type="workStation"
              handleUpdateClicked={canUpdate ? handleUpdateWorkStation : undefined}
            />
            <AssetAssociated
              className={styles.item}
              assetRef={productionUnit.robot}
              type="robot"
              handleUpdateClicked={canUpdate ? handleUpdateRobot : undefined}
            />

            <ProductionLinesLinked className={styles.item} productionUnitId={productionUnit.id} />
          </div>
        )}
      </Can>
      <Outlet></Outlet>
    </Page>
  );
};

export default ProductionUnitPage;
