import React, { useCallback, useContext, useEffect, useState, FC } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { getTranslationsDataGrid } from '../i18n';
import AppStateContext from '../context/AppStateContext';
import { TypeDataSource, TypeFilterValue, TypeSortInfo } from '@inovua/reactdatagrid-community/types';
import { createBody } from './datagridTools';
import { useTranslation } from 'react-i18next';
import styles from './DataGrid.module.scss';
import { DispatchResourceAction, SET_RESOURCES } from '../context/AppStateReducer';
import { Can, useAbility } from '@casl/react';
import AbilityContext from '../context/AbilityContext';
import { Button } from 'react-bootstrap';

import { DataGridProps, FilterValue, SortInfo } from '../@types/webapp';
import NotAllowed from '../components/Access/NotAllowed';
import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import { isEqual } from 'lodash';

const DataGrid: FC<DataGridProps> = ({
  title,
  columns,
  loadData,
  resourceName,
  LeftActions,
  RightActions,
  rowHeight
}) => {
  const { dispatch, state } = useContext(AppStateContext);
  const [i18n, seti18n] = useState(getTranslationsDataGrid(state.language));
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const [enableFiltering, setEnableFiltering] = useState(columns.some((c) => c.filterProps?.value));
  const [dataSource, setDataSource] = useState<TypeDataSource>();
  const [loading, setLoading] = useState(false);

  const [defaultFilterValue] = useState<FilterValue[]>(
    columns.reduce((acc, column) => {
      if (!column.filterProps) {
        return acc;
      }

      let filter: FilterValue;
      const filterName = column.name || 'unknown';
      if (column?.filterProps.type === 'select') {
        filter = {
          ...column?.filterProps,
          name: filterName,
          operator: 'inlist',
          type: 'select',
          value: column?.filterProps.value || '',
          _source: column?.filterProps._source
        };
      } else {
        filter = {
          name: filterName,
          operator: 'startsWith',
          type: 'string',
          value: column?.filterProps.value || '',
          _source: column?.filterProps._source
        };
      }
      return [...acc, filter];
    }, [] as FilterValue[])
  );

  const [filterValue, setFilterValue] = useState<TypeFilterValue>(defaultFilterValue);
  const [sortInfo, setSortInfo] = useState<TypeSortInfo>({
    dir: 1,
    name: columns?.[0]?.name || ''
  });
  const [from, setFrom] = useState(0);
  const [size, setSize] = useState(50);

  useEffect(() => {
    const paginationList = state.resources[resourceName];
    setDataSource(
      Promise.resolve({
        data: paginationList?.items || [],
        count: paginationList?.total || 0
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceName, state.resources]);

  const handleLoadData = async () => {
    setLoading(true);

    try {
      const body = createBody({
        skip: from,
        limit: size,
        sortInfo: sortInfo as SortInfo,
        filterValue: filterValue as FilterValue[],
        columns
      });
      const { data } = ability.can('get', resourceName)
        ? await loadData(body)
        : {
            data: { items: [], total: 0 }
          };

      dispatch({
        payload: data,
        resourceName,
        type: SET_RESOURCES
      } as DispatchResourceAction);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    handleLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, from, size, ability, sortInfo]);

  const renderColumnFilterContextMenu = useCallback((menuProps) => {
    const operatorsFilterColumn = [
      'operator-contains',
      'operator-eq',
      'operator-startsWith',
      'operator-endsWith',
      'enableFilter',
      'disableFilter',
      'clearFilter',
      'clearAllFilters'
    ];
    menuProps.items = menuProps.items.filter((item: { itemId: string }) =>
      operatorsFilterColumn.some((itemsOperator: string) => itemsOperator === item.itemId)
    );
  }, []);

  const renderColumnContextMenu = useCallback((menuProps) => {
    const columnOptions = [
      'sortAsc',
      'sortDesc',
      'unsort',
      'columns',
      'showFilteringRow',
      'hideFilteringRow'
    ];
    menuProps.items = menuProps.items.filter((item: { itemId: string }) =>
      columnOptions.some((itemOption: string) => itemOption === item.itemId)
    );
  }, []);

  useEffect(() => {
    seti18n(getTranslationsDataGrid(state.language));
  }, [state.language]);

  const handleEnableFiltering = () => {
    const value = !enableFiltering;
    if (!value) {
      setFilterValue(filterValue?.map((filter) => ({ ...filter, value: '', _source: undefined })) || []);
    }
    setEnableFiltering(value);
  };

  const onFilterChange = (newFilterValue: any) => {
    //Fix side effect when filter change
    if (!isEqual(newFilterValue, filterValue)) {
      setFilterValue(newFilterValue);
      setFrom(0);
    }
  };

  return (
    <>
      <h1>{title}</h1>
      <Can not I="get" a={resourceName} ability={ability}>
        <NotAllowed />
      </Can>
      <Can I="get" a={resourceName} ability={ability}>
        <div className={`${styles.buttons}`}>
          <div className={`${styles.leftButtons}`}>{LeftActions}</div>
          <div className={`${styles.rightButtons}`}>
            <Button variant="secondary" onClick={handleEnableFiltering}>
              {t('datagrid.filters')}
            </Button>
            {RightActions}
          </div>
        </div>

        <ReactDataGrid
          theme="default-orka"
          className={styles.grid}
          columns={columns}
          rowHeight={rowHeight}
          loading={loading}
          dataSource={dataSource || []}
          editable={false}
          renderColumnFilterContextMenu={renderColumnFilterContextMenu}
          renderColumnContextMenu={renderColumnContextMenu}
          showZebraRows
          defaultLimit={size}
          pagination={'remote'}
          columnDefaultWidth={250}
          i18n={i18n}
          filterValue={filterValue}
          enableFiltering={enableFiltering}
          defaultSortInfo={sortInfo}
          onFilterValueChange={onFilterChange}
          onLimitChange={setSize}
          onSkipChange={setFrom}
          onSortInfoChange={setSortInfo}
          defaultFilterValue={defaultFilterValue}
          loadingText={t('status.loading')}
        />
      </Can>
    </>
  );
};

export default DataGrid;
