import { Button } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const NodeRedPage = () => {
  const [token, setToken] = useState();

  useEffect(() => {
    const assignToken = async () => {
      const {
        accessToken: { jwtToken }
      } = (await Auth.currentSession()) as any;
      setToken(jwtToken);
    };
    assignToken();
  }, []);
  return (
    <>
      <div style={{ textAlign: 'left', marginBottom: '30px' }}>
        <Button
          onClick={() => {
            const iframe = document.querySelector('iframe');
            iframe?.contentWindow?.postMessage(`Message to node red ${new Date().toISOString()}`, '*');
          }}
        >
          TEST
        </Button>
      </div>
      {token && (
        <div>
          <iframe
            title="Node Red"
            src={`/node-red?access_token=${token}`}
            style={{ width: 'calc(100vw - 400px)', height: 'calc(100vh - 200px)' }}
          />
        </div>
      )}

      <Outlet></Outlet>
    </>
  );
};

export default NodeRedPage;
