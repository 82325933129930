import React, { FC, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Site, SitePost, SitePut } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import CountrySelect from '../components/Country/CountrySelect';
import SiteIdSelect from './SiteIdSelect';
import TimezoneSelect from '../components/Timezone/TimezoneSelect';
import OperatedByInput, { MEMNON } from './OperatedByInput';

type UpdateSiteProps = FormProps<SitePut> & {
  mode: 'update';
  site: Site;
};

type CreateSiteProps = FormProps<SitePost> & {
  mode: 'create';
  //Property not relevent for creation but only to simplify the code
  site?: undefined;
  onMemberCountChange?: undefined;
};

const SiteForm: FC<CreateSiteProps | UpdateSiteProps> = forwardRef(
  ({ onChange, disabled, site, mode }, ref) => {
    const { t } = useTranslation('i18n');

    const [id, setId] = useState<string | undefined>();
    const [country, setCountry] = useState<string>();
    const [city, setCity] = useState<string>();
    const [timezone, setTimezone] = useState<string>();
    const [operatedBy, setOperatedBy] = useState<string>(MEMNON);

    const [errors, setErrors] = useState<Record<string, string>>({});

    useEffect(() => {
      setId(site?.id);
      setCountry(site?.country);
      setCity(site?.city);
      setTimezone(site?.timezone);
      setOperatedBy(site?.operatedBy || MEMNON);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site]);

    useImperativeHandle(ref, () => ({
      isValid() {
        const errors: Record<string, string> = {};
        if (!country) {
          errors.country = t('form.error.required');
        }
        if (!id) {
          errors.id = t('form.error.required');
        }
        if (!city || !city.trim().length) {
          errors.city = t('form.error.required');
        }
        if (!timezone) {
          errors.timezone = t('form.error.required');
        }
        if (!operatedBy) {
          errors.operatedBy = t('form.error.required');
        }

        setErrors(errors);
        return !Object.keys(errors).length;
      }
    }));

    useEffect(() => {
      if (country || id || city || timezone || operatedBy) {
        onChange(
          mode === 'create'
            ? {
                country,
                id,
                city: `${city}`.trim(),
                timezone,
                operatedBy
              }
            : {
                country,
                city: `${city}`.trim(),
                timezone,
                operatedBy
              }
        );
      }
    }, [city, country, id, mode, onChange, operatedBy, timezone]);

    const disableComp = disabled;

    const cleanError = (field: string) => {
      const { [field]: fieldError, ...otherErrors } = errors;
      if (fieldError) {
        //Clean the error
        setErrors(otherErrors);
      }
    };

    const handleCountryChanged = (country?: string) => {
      setCountry(country);
      cleanError('country');
    };
    const handleCityChanged = (city?: string) => {
      setCity(city);
      cleanError('city');
    };
    const handleIdChanged = (id?: string) => {
      setId(id);
      cleanError('id');
    };
    const handleTimezoneChanged = (timezone?: string) => {
      setTimezone(timezone);
      cleanError('timezone');
    };
    const handleOperatedByChanged = (operatedBy: string) => {
      setOperatedBy(operatedBy);
      cleanError('operatedBy');
    };

    return (
      <Container>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('site.country')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <CountrySelect
              value={country}
              onChange={handleCountryChanged}
              isMulti={false}
              disabled={disableComp || !!site}
              isInvalid={!!errors.country}
            />

            <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('site.id')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            {site ? (
              <Form.Control type="text" value={site.id} disabled />
            ) : (
              <SiteIdSelect
                countryCode={country}
                value={id}
                onChange={handleIdChanged}
                disabled={disableComp}
                isInvalid={!!errors.id}
              />
            )}

            <Form.Control.Feedback type="invalid">{errors.id}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('site.city')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <Form.Control
              type="text"
              value={city}
              onChange={(event) => handleCityChanged(event.target.value)}
              disabled={disableComp}
              isInvalid={!!errors.city}
            />
            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('site.timezone')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <TimezoneSelect
              value={timezone}
              onChange={handleTimezoneChanged}
              disabled={disableComp}
              isInvalid={!!errors.timezone}
            />
            <Form.Control.Feedback type="invalid">{errors.timezone}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('site.operatedBy')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <div className={formStyles.inputTop}>
              <OperatedByInput
                value={operatedBy}
                onChange={handleOperatedByChanged}
                disabled={disableComp}
                isInvalid={!!errors.operatedBy}
              />
            </div>
            <Form.Control.Feedback type="invalid">{errors.operatedBy}</Form.Control.Feedback>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default SiteForm;
