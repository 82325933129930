import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Box from '@mui/material/Box';
import styles from './Navigation.module.scss';
import AdministrationList from './AdministrationList';
import ConfigurationList from './ConfigurationList';
const SideMenu = () => {
  return (
    <div>
      <Drawer variant="permanent" open className={styles.drawer}>
        <Box component="nav" className={styles.drawer}>
          <Toolbar />
          <Divider />
          <AdministrationList></AdministrationList>
          <ConfigurationList></ConfigurationList>
        </Box>
      </Drawer>
    </div>
  );
};

export default SideMenu;
