import React from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from '../datagrid/DataGrid';
import api from '../api/Api';
import { Role } from '@memnon.orka/orka-sdk';
import Badge from 'react-bootstrap/Badge';
import gridStyles from '../datagrid/DataGrid.module.scss';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Can, useAbility } from '@casl/react';

import { Link, Outlet } from 'react-router-dom';
import { BodyApi, DataGridColumn } from '../@types/webapp';
import { getRolePath } from '../navigation/AppRoutes';
import AbilityContext from '../context/AbilityContext';
import { Resource } from '../tools/resourceTools';
import ResourceSelectFilter from '../components/Select/ResourceSelectFilter';
import { convertApiResponseToSelectOptions } from '../components/Select/selectTools';
import { DEFAULT_TEXT_DELAY } from '../datagrid/datagridTools';

const RoleList = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const navigate = useNavigate();
  const location = useLocation();

  const columns: DataGridColumn[] = [
    {
      name: 'name',
      header: t('role.name'),
      render: ({ data }: any) => {
        return <Link to={getRolePath({ mode: 'update', roleId: data.id })}>{data.name}</Link>;
      },
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'description',
      header: t('role.description'),
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'permissionSets',
      header: t('role.permissionSets'),
      sortable: false,
      render: ({ data: { permissionSets } }: { data: Role }) => {
        return (
          <div className={gridStyles.badges}>
            {permissionSets?.length ? (
              (permissionSets || [])
                .sort((p1, p2) => p1.name.localeCompare(p2.name))
                .map((role) => (
                  <Badge bg="primary" key={role.id}>
                    {role.name}
                  </Badge>
                ))
            ) : (
              <Badge key="empty" bg="secondary">
                {t('role.empty')}
              </Badge>
            )}
          </div>
        );
      },
      filterProps: {
        type: 'select',
        name: 'permissionSets.id',
        value: location.state?.permissionSet?.id,
        _source: location.state?.permissionSet
          ? { value: location.state.permissionSet.id, label: location.state.permissionSet.name }
          : undefined
      },
      filterEditor: ResourceSelectFilter,
      filterEditorProps: {
        isMulti: true,
        loadData: async (search?: string) =>
          convertApiResponseToSelectOptions({
            response: await api.permissions.listPermissionSets(
              search
                ? {
                    size: 200,
                    name: `contains:${search}`
                  }
                : {
                    size: 200
                  }
            ),
            idPath: 'id',
            labelPath: 'name'
          })
      },
      defaultFlex: 1,
      minWidth: 200
    }
  ];

  const loadData = async (body: BodyApi) => {
    return api.roles.listRoles(body);
  };

  return (
    <>
      <DataGrid
        title={t(`role.title`)}
        columns={columns}
        loadData={loadData}
        resourceName={Resource.ROLE}
        RightActions={[
          <Can I="post" a={Resource.ROLE} ability={ability} key="createButton">
            <Button
              key="create"
              onClick={() => {
                navigate(getRolePath({ mode: 'create' }));
              }}
            >
              {t('role.create')}
            </Button>
          </Can>
        ]}
      />
      <Outlet></Outlet>
    </>
  );
};

export default RoleList;
