import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './orka_theme.scss';

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>,
  document.getElementById('root')
);
