import React, { FC, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Member, RoleRef } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import MemberPronounsSelect from './MemberPronounsSelect';
import CountrySelect from '../components/Country/CountrySelect';
import LanguageSelect from '../components/Language/LanguageSelect';
import RoleSelect from '../components/Select/RoleSelect';

type UpdateProps = FormProps<Member> & {
  mode: 'update';
  member: Member;
};

type CreateProps = FormProps<Member> & {
  mode: 'create';
  //Property not relevent for creation but only to simplify the code
  member?: undefined;
};

const PHONE_PATTERN = /\+[1-9][0-9]{0,24}$/;

const MemberForm: FC<CreateProps | UpdateProps> = forwardRef(({ onChange, disabled, member, mode }, ref) => {
  const { t } = useTranslation('i18n');

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [pronouns, setPronouns] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [roles, setRoles] = useState<RoleRef[] | undefined>();
  const [country, setCountry] = useState<string>();
  const [language, setLanguage] = useState<string>();

  const [errors, setErrors] = useState<Record<string, string>>({});

  useImperativeHandle(ref, () => ({
    isValid() {
      const errors: Record<string, string> = {};
      if (!firstName) {
        errors.firstName = t('form.error.required');
      }
      if (!lastName) {
        errors.lastName = t('form.error.required');
      }
      if (!pronouns) {
        errors.pronouns = t('form.error.required');
      }
      if (!email) {
        errors.email = t('form.error.required');
      }
      if (!phone) {
        errors.phone = t('form.error.required');
      } else if (!PHONE_PATTERN.test(phone)) {
        errors.phone = t('member.error.phone');
      }
      if (!country) {
        errors.country = t('form.error.required');
      }
      if (!language) {
        errors.language = t('form.error.required');
      }
      if (!roles?.length) {
        errors.roles = t('form.error.required');
      }
      setErrors(errors);
      return !Object.keys(errors).length;
    }
  }));

  useEffect(() => {
    setFirstName(member?.firstName);
    setLastName(member?.lastName);
    setPronouns(member?.pronouns);
    setEmail(member?.email);
    setPhone(member?.phone || '+');
    setRoles(member?.roles);
    setCountry(member?.country);
    setLanguage(member?.language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const cleanError = (field: string) => {
    const { [field]: fieldError, ...otherErrors } = errors;
    if (fieldError) {
      //Clean the error
      setErrors(otherErrors);
    }
  };

  useEffect(() => {
    if (firstName || lastName || pronouns || email || phone || country || language || roles) {
      onChange({
        firstName,
        lastName,
        pronouns,
        email,
        phone,
        country,
        language,
        roles
      });
    }
  }, [country, email, firstName, language, lastName, onChange, phone, pronouns, roles]);

  const disableComp = disabled;

  const handleFirstNameChanged = (e: React.ChangeEvent<any>) => {
    setFirstName(e.target.value);
    cleanError('firstName');
  };
  const handleLastNameChanged = (e: React.ChangeEvent<any>) => {
    setLastName(e.target.value);
    cleanError('lastName');
  };
  const handlePronounsChanged = (value: string | undefined) => {
    setPronouns(value);
    cleanError('pronouns');
  };
  const handleEmailChanged = (e: React.ChangeEvent<any>) => {
    setEmail(e.target.value);
    cleanError('email');
  };
  const handlePhoneChanged = (e: React.ChangeEvent<any>) => {
    setPhone(e.target.value);
    cleanError('phone');
  };
  const handleCountryChanged = (value: string | undefined) => {
    setCountry(value);
    cleanError('country');
  };
  const handleLanguageChanged = (value: string | undefined) => {
    setLanguage(value);
    cleanError('language');
  };
  const handleRolesChanged = (roles: RoleRef[] | undefined) => {
    setRoles(roles);
    cleanError('roles');
  };

  return (
    <Container>
      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.firstName')}
          <span className={formStyles.required}>*</span>
        </Col>
        <Col sm={8} className={formStyles.input}>
          <Form.Control
            id="firstName"
            required
            type="text"
            disabled={disableComp}
            value={firstName}
            isInvalid={!!errors.firstName}
            onChange={handleFirstNameChanged}
          />
          <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.lastName')}
          <span className={formStyles.required}>*</span>
        </Col>
        <Col sm={8} className={formStyles.input}>
          <Form.Control
            id="lastName"
            required
            type="text"
            disabled={disableComp}
            value={lastName}
            isInvalid={!!errors.lastName}
            onChange={handleLastNameChanged}
          />
          <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.pronouns')}
          <span className={formStyles.required}>*</span>
        </Col>
        <Col sm={8} className={formStyles.input}>
          <MemberPronounsSelect
            value={pronouns}
            onChange={handlePronounsChanged}
            isInvalid={!!errors.pronouns}
            disabled={disableComp}
          />
          <Form.Control.Feedback type="invalid">{errors.pronouns}</Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.email')}
          <span className={formStyles.required}>*</span>
        </Col>
        <Col sm={8} className={formStyles.input}>
          <Form.Control
            id="email"
            required
            type="text"
            disabled={disableComp || mode === 'update'}
            value={email}
            isInvalid={!!errors.email}
            onChange={handleEmailChanged}
          />
          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.phone')}
          <span className={formStyles.required}>*</span>
        </Col>
        <Col sm={8} className={formStyles.input}>
          <Form.Control
            id="phone"
            required
            type="text"
            disabled={disableComp}
            value={phone}
            isInvalid={!!errors.phone}
            onChange={handlePhoneChanged}
          />
          <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.country')}
          <span className={formStyles.required}>*</span>
        </Col>
        <Col sm={8} className={formStyles.input}>
          <CountrySelect
            value={country}
            onChange={handleCountryChanged}
            isInvalid={!!errors.country}
            disabled={disableComp}
            isMulti={false}
          />
          <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.language')}
          <span className={formStyles.required}>*</span>
        </Col>
        <Col sm={8} className={formStyles.input}>
          <LanguageSelect
            value={language}
            onChange={handleLanguageChanged}
            isInvalid={!!errors.language}
            disabled={disableComp}
          />
          <Form.Control.Feedback type="invalid">{errors.language}</Form.Control.Feedback>
        </Col>
      </Row>

      <Row>
        <Col sm={4} className={formStyles.field}>
          {t('member.roles')}
        </Col>
        <Col sm={8} className={formStyles.input}>
          <RoleSelect
            value={roles}
            onChange={handleRolesChanged}
            isInvalid={!!errors.roles}
            disabled={disableComp}
          />
          <Form.Control.Feedback type="invalid">{errors.language}</Form.Control.Feedback>
        </Col>
      </Row>
    </Container>
  );
});

export default MemberForm;
