import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import api from '../../api/Api';
import { RoleRef } from '@memnon.orka/orka-sdk';

import formStyles from '../../form/form.module.scss';
import AppStateContext from '../../context/AppStateContext';
import { Resource } from '../../tools/resourceTools';
import { DispatchResourceAction, SET_RESOURCES } from '../../context/AppStateReducer';

type Props = {
  onChange: (value?: RoleRef[]) => void;
  defaultValue?: RoleRef[];
  value?: RoleRef[];
  disabled?: boolean;
  isInvalid?: boolean;
  isClearable?: boolean;
};

type Option = {
  value: string;
  label: any;
  _original: RoleRef;
};

const RoleSelect: FC<Props> = ({ onChange, value, disabled, isInvalid, isClearable, defaultValue }) => {
  const { t } = useTranslation('i18n');

  const { dispatch } = useContext(AppStateContext);

  const [selected, setSelected] = useState<Option | Option[]>();

  const composeOption = (role: RoleRef): Option => {
    return {
      _original: role,
      value: role.id,
      label: role.name
    };
  };

  useEffect(() => {
    setSelected(!value ? undefined : value.map(composeOption));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, t]);

  const handleOnChange = (items?: Option[]) => {
    onChange(items ? items.map((option) => option._original) : undefined);
  };

  const loadData = async (search?: string) => {
    const request: Record<string, string | number | boolean> = search
      ? {
          size: 200,
          name: `startsWith:${search}`
        }
      : {
          size: 200
        };

    const roles = await api.roles.listRoles(request);
    let data = roles.data.items.map(composeOption);
    dispatch({
      payload: roles.data,
      resourceName: Resource.ROLE,
      type: SET_RESOURCES
    } as DispatchResourceAction);

    //Force to add defaultValue as selection if not present and no search
    defaultValue?.forEach((role) => {
      if (!search && !roles.data.items.some((a) => a.id === role.id)) {
        data = [composeOption(role), ...data];
      }
    });

    return data;
  };
  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <AsyncSelect
        key={`select-roles`}
        defaultOptions
        loadOptions={loadData}
        className={isInvalid ? formStyles.invalid : formStyles.valid}
        onChange={handleOnChange as any}
        isMulti={true}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
        isClearable={isClearable || false}
      />
    </>
  );
};

export default RoleSelect;
