import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../dialog/DefaultCrudDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { getSitePath } from '../navigation/AppRoutes';
import { Site, SitePost, SitePut } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import AppStateContext from '../context/AppStateContext';
import { ADD_RESOURCE, DispatchResourceAction, UPDATE_RESOURCE } from '../context/AppStateReducer';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';
import SiteForm from './SiteForm';

const SiteDialog = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();

  const ref = useRef();

  const { siteId } = useParams();

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [existingSite, setExistingSite] = useState<Site>();
  const [updatedSite, setUpdatedSite] = useState<Partial<Site>>();
  const [loading, setLoading] = useState(false);

  const handleLoadSite = async (siteId: string) => {
    setLoading(true);

    try {
      const { data } = await api.sites.getSite(siteId);
      setExistingSite(data);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (siteId) {
      handleLoadSite(siteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  const handleClose = () => {
    setShowModal(false);
    navigate(getSitePath({ mode: 'list' }));
  };

  const handleCreate = async (site: SitePost) => {
    try {
      const { data: siteCreated } = await api.sites.createSite(site as SitePost);
      dispatch({
        payload: siteCreated,
        resourceName: Resource.SITE,
        type: ADD_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`site.success.create`, { id: siteCreated.id }));
      return true;
    } catch (error: any) {
      if (
        error?.error?.error?.details?.some(
          (detail: any) => detail.message === 'Not unique' && detail.path?.some((p: string) => p === 'id')
        )
      ) {
        toast.error(t('site.error.notUnique', { id: site.id }), {
          autoClose: false
        });
      } else {
        toast.error(t(getErrorKey(error)), {
          autoClose: false
        });
      }
      return false;
    }
  };

  const handleUpdate = async (siteId: string, site: SitePut) => {
    try {
      const { data: siteUpdated } = await api.sites.updateSite(siteId, {
        city: site.city,
        operatedBy: site.operatedBy,
        timezone: site.timezone
      });
      dispatch({
        payload: siteUpdated,
        resourceName: Resource.SITE,
        type: UPDATE_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`site.success.update`, { id: siteUpdated.id }));
      return true;
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return false;
    }
  };

  const handleSave = async () => {
    if (!(ref?.current as any)?.isValid()) {
      return false;
    }

    setSaving(true);
    let response: boolean;
    if (siteId) {
      response = await handleUpdate(siteId, updatedSite as SitePut);
    } else {
      response = await handleCreate(updatedSite as SitePost);
    }
    setSaving(false);
    return response;
  };

  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: 'post',
        resource: Resource.SITE
      }}
      title={siteId ? t(`site.update`) : t(`site.create`)}
      closeLabel={t('action.cancel')}
      saveLabel={siteId ? t(`action.update`) : t(`action.create`)}
      onClose={handleClose}
      saving={saving}
      onSave={handleSave}
      loading={loading}
    >
      {existingSite ? (
        <SiteForm ref={ref} mode="update" site={existingSite} onChange={setUpdatedSite} disabled={saving} />
      ) : (
        <SiteForm ref={ref} mode="create" onChange={setUpdatedSite} disabled={saving} />
      )}
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default SiteDialog;
