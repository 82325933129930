import React, { FC, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { FilterValue } from '../../@types/webapp';

type Props = {
  onChange: (value: FilterValue) => {};
  filterValue: FilterValue;
  isMulti?: boolean;
  loadData: (filter?: string) => Promise<{ label: string; value: string }[]>;
};

const ResourceSelectFilter: FC<Props> = forwardRef(({ onChange, filterValue, loadData, isMulti }, ref) => {
  const { t } = useTranslation('i18n');
  const handleOnChange = (items: any) => {
    const values = items ? (Array.isArray(items) ? items : [items]) : [];
    onChange({
      ...filterValue,
      value: values.map((i: any) => i.value).join(','),
      _source: items
    });
  };

  return (
    <div>
      <AsyncSelect
        loadOptions={loadData}
        defaultOptions
        onChange={handleOnChange}
        isMulti={isMulti}
        value={filterValue._source}
        noOptionsMessage={() => t('common.noValue')}
      />
    </div>
  );
});

export default ResourceSelectFilter;
