import React, { FC, useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionSet } from '@memnon.orka/orka-sdk';
import { UtilRequiredKeys } from '../@types/webapp';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../form/PermissionSetsInput.module.scss';
import InfoIcon from '@mui/icons-material/Info';
import { getPermissionSetPath } from '../navigation/AppRoutes';
import AppStateContext from '../context/AppStateContext';
import LoadingComponent from '../components/Loading/LoadingComponent';
import { DispatchResourceAction, SET_RESOURCES } from '../context/AppStateReducer';
import api from '../api/Api';
import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import { Resource } from '../tools/resourceTools';
import OrkaLink from '../components/Link/OrkaLink';

type Props = {
  onChange: (permissionSets: PermissionSet[]) => void;
  value: UtilRequiredKeys<PermissionSet, 'id'>[];
  disabled?: boolean;
};

const PermissionSetsInput: FC<Props> = ({ onChange, disabled, value }) => {
  const { t } = useTranslation('i18n');

  const { dispatch, state } = useContext(AppStateContext);
  const [loading, setLoading] = useState(false);

  const handleLoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.permissions.listPermissionSets({
        from: 0,
        size: 1000,
        sortBy: 'asc:name'
      });
      dispatch({
        payload: data,
        resourceName: Resource.PERMISSION_SET,
        type: SET_RESOURCES
      } as DispatchResourceAction);
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    handleLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (permissionSet: PermissionSet, checked: boolean) => {
    let newValue = checked ? [...value, permissionSet] : value.filter((s) => s.id !== permissionSet.id);
    onChange(newValue);
  };

  const data = state.resources?.[Resource.PERMISSION_SET]?.items || value;

  return (
    <div className={styles.root}>
      {loading ? (
        <LoadingComponent size="sm" />
      ) : (
        data.map((permissionSet: any) => (
          <div key={`PS_${permissionSet.id}`} className={styles.row}>
            <Form.Check
              className={styles.checkbox}
              id={permissionSet.id}
              label={permissionSet.name}
              checked={value.some((s) => s.id === permissionSet.id)}
              onChange={(event: any) => handleOnChange(permissionSet, event.target.checked)}
              disabled={disabled}
            />
            <OverlayTrigger
              placement="auto"
              overlay={(props) => <Tooltip {...props}>{permissionSet.description}</Tooltip>}
            >
              <InfoIcon className={styles.smallIcon} />
            </OverlayTrigger>
            <OrkaLink
              url={getPermissionSetPath({ mode: 'update', permissionSetId: permissionSet.id })}
              openNewPage
              label=""
            />
          </div>
        ))
      )}
    </div>
  );
};

export default PermissionSetsInput;
