import React, { FC, useState, useEffect } from 'react';
import styles from './TypeLabel.module.scss';

import { getAssetTypeLabel } from '../../tools/assetTools';

type Props = { type: string; className?: string };

const AssetTypeLabel: FC<Props> = ({ type, className }) => {
  const [label, setLabel] = useState<string>();

  useEffect(() => {
    setLabel(getAssetTypeLabel(type));
  }, [type]);

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <div className={styles.type}>{label}</div>
    </div>
  );
};

export default AssetTypeLabel;
