import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../dialog/DefaultCrudDialog';
import PermissionSetForm from './PermissionSetForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getPermissionSetPath } from '../navigation/AppRoutes';
import { PermissionSet, PermissionSetPost, PermissionSetPut } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import AppStateContext from '../context/AppStateContext';
import {
  ADD_RESOURCE,
  DELETE_RESOURCE,
  DispatchResourceAction,
  UPDATE_RESOURCE
} from '../context/AppStateReducer';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';

const PermissionSetDialog = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();

  const ref = useRef();

  const { permissionSetId } = useParams();

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [permissionSet, setPermissionSet] = useState<Partial<PermissionSet>>();
  const [existingPermissionSet, setExistingPermissionSet] = useState<PermissionSet>();
  const [memberCount, setMemberCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    navigate(getPermissionSetPath({ mode: 'list' }));
  };

  const handleLoadPermissionSet = async (permissionSetId: string) => {
    setLoading(true);

    try {
      const { data } = await api.permissions.getPermissionSet(permissionSetId);
      setExistingPermissionSet(data);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (permissionSetId) {
      handleLoadPermissionSet(permissionSetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionSetId]);

  const handleSave = async () => {
    if (!(ref?.current as any)?.isValid()) {
      return false;
    }

    setSaving(true);
    try {
      if (permissionSetId) {
        const { data: permissionSetUpdated } = await api.permissions.updatePermissionSet(
          permissionSetId,
          permissionSet as PermissionSetPut
        );
        dispatch({
          payload: permissionSetUpdated,
          resourceName: Resource.PERMISSION_SET,
          type: UPDATE_RESOURCE
        } as DispatchResourceAction);
        toast.success(t(`permission-set.success.update`, { name: permissionSetUpdated.name }));
      } else {
        const { data: permissionSetCreated } = await api.permissions.createPermissionSet(
          permissionSet as PermissionSetPost
        );
        dispatch({
          payload: permissionSetCreated,
          resourceName: Resource.PERMISSION_SET,
          type: ADD_RESOURCE
        } as DispatchResourceAction);
        toast.success(t(`permission-set.success.create`, { name: permissionSetCreated.name }));
      }
      setSaving(false);
      return true;
    } catch (error) {
      setSaving(false);
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return false;
    }
  };
  const handleDelete = async () => {
    try {
      if (permissionSetId) {
        await api.permissions.deletePermissionSet(permissionSetId);
        dispatch({
          payload: {
            id: permissionSetId
          },
          resourceName: Resource.ROLE,
          type: DELETE_RESOURCE
        } as DispatchResourceAction);
        toast.success(t(`permission-set.success.delete`));
      }
      setSaving(false);
      return true;
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return false;
    }
  };

  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: 'post',
        resource: Resource.ROLE
      }}
      title={permissionSetId ? t(`permission-set.update`) : t(`permission-set.create`)}
      closeLabel={t('action.cancel')}
      saveLabel={permissionSetId ? t(`action.update`) : t(`action.create`)}
      onClose={handleClose}
      saving={saving}
      onDelete={permissionSetId && permissionSet ? handleDelete : undefined}
      onSave={handleSave}
      deleteConfirmLabel={
        <div
          dangerouslySetInnerHTML={{
            __html: t('permission-set.confirmDelete', { count: memberCount, name: permissionSet?.name })
          }}
        />
      }
      loading={loading}
    >
      {existingPermissionSet ? (
        <PermissionSetForm
          ref={ref}
          mode="update"
          permissionSet={existingPermissionSet}
          onChange={setPermissionSet}
          disabled={saving}
          onMemberCountChange={setMemberCount}
        />
      ) : (
        <PermissionSetForm ref={ref} mode="create" onChange={setPermissionSet} disabled={saving} />
      )}
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default PermissionSetDialog;
