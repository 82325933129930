import React, { FC, forwardRef, useEffect, useState, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, AssetPost, AssetPut } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import AssetTypeSelect from './AssetTypeSelect';
import AssetCharacteristicForm from './AssetCharacteristicForm';
import AssetReferenceForm from './AssetReferenceForm';
import AssetLocationForm from './AssetLocationForm';
import AssetStatusForm from './AssetStatusForm';
import AssetPropertiesForm from './AssetPropertiesForm';

type UpdateAssetProps = FormProps<Asset> & {
  mode: 'update';
  asset: Asset;
};

type CreateAssetProps = FormProps<Asset> & {
  mode: 'create';
  //Property not relevent for creation but only to simplify the code
  asset?: undefined;
};

const AssetForm: FC<CreateAssetProps | UpdateAssetProps> = forwardRef(
  ({ onChange, disabled, asset, mode }, ref) => {
    const { t } = useTranslation('i18n');

    const [typeValidated, setTypeValidated] = useState(false);
    const [data, setData] = useState<Partial<Asset & { type?: string }>>({});
    const [errors, setErrors] = useState<Record<string, string>>({});

    const characteristicRef = useRef();
    const referenceRef = useRef();
    const locationRef = useRef();
    const statusRef = useRef();
    const propertiesRef = useRef();

    useEffect(() => {
      setTypeValidated(!!asset);
      setData(asset || {});
    }, [asset]);

    useImperativeHandle(ref, () => ({
      isValid() {
        if (data.type && !typeValidated) {
          setTypeValidated(true);
          return false;
        }

        const errors: Record<string, string> = {};
        if (!data.type) {
          errors.type = t('form.error.required');
        }
        const allValid = [characteristicRef, referenceRef, locationRef, statusRef, propertiesRef].reduce(
          (acc, ref) => {
            return !!(ref?.current as any)?.isValid() && acc;
          },
          true
        );
        setErrors(errors);
        return allValid && !Object.keys(errors).length;
      }
    }));

    const disableComp = disabled;

    const cleanError = (field: string) => {
      const { [field]: fieldError, ...otherErrors } = errors;
      if (fieldError) {
        //Clean the error
        setErrors(otherErrors);
      }
    };

    const handleTypeChanged = (type?: string) => {
      setData({ ...data, type } as any);
      cleanError('type');
    };

    const handleAssetChange = (asset: Partial<AssetPost | AssetPut>) => {
      const updated = { ...data, ...asset };
      setData(updated);
      onChange(updated);
    };

    return (
      <Container>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('asset.type')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <AssetTypeSelect
              value={data.type}
              onChange={handleTypeChanged}
              disabled={disableComp || typeValidated}
              isInvalid={!!errors.type}
            />
            <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
          </Col>
        </Row>

        {typeValidated && (
          <>
            <Row>
              <Col className={formStyles.input}>
                <AssetPropertiesForm
                  ref={propertiesRef}
                  mode={mode as any}
                  asset={data as any}
                  onChange={handleAssetChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className={formStyles.input}>
                <AssetReferenceForm
                  ref={referenceRef}
                  mode={mode as any}
                  asset={data}
                  onChange={handleAssetChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className={formStyles.input}>
                <AssetCharacteristicForm
                  ref={characteristicRef}
                  mode={mode as any}
                  asset={data}
                  onChange={handleAssetChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className={formStyles.input}>
                <AssetLocationForm
                  ref={locationRef}
                  mode={mode as any}
                  asset={data}
                  onChange={handleAssetChange}
                />
              </Col>
            </Row>
            <Row>
              <Col className={formStyles.input}>
                <AssetStatusForm
                  ref={statusRef}
                  mode={mode as any}
                  asset={data}
                  onChange={handleAssetChange}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
);

export default AssetForm;
