import React from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from '../datagrid/DataGrid';
import api from '../api/Api';
import Badge from 'react-bootstrap/Badge';
import gridStyles from '../datagrid/DataGrid.module.scss';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Can, useAbility } from '@casl/react';

import { Link, Outlet } from 'react-router-dom';
import { BodyApi, DataGridColumn } from '../@types/webapp';
import { getPermissionSetPath } from '../navigation/AppRoutes';
import AbilityContext from '../context/AbilityContext';
import { Resource } from '../tools/resourceTools';
import ResourceSelectFilter from '../components/Select/ResourceSelectFilter';
import { convertApiResponseToSelectOptions } from '../components/Select/selectTools';
import { DEFAULT_TEXT_DELAY } from '../datagrid/datagridTools';
import { PermissionSet } from '@memnon.orka/orka-sdk';
import { getPermissionShortLabel } from '../tools/permissionTools';
import { capitalize } from '../tools/stringTools';
import styles from './PermissionSetList.module.scss';

const PermissionSetList = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const navigate = useNavigate();

  const columns: DataGridColumn[] = [
    {
      name: 'name',
      header: t('permission-set.name'),
      render: ({ data }: any) => {
        return (
          <Link to={getPermissionSetPath({ mode: 'update', permissionSetId: data.id })}>{data.name}</Link>
        );
      },
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'description',
      header: t('permission-set.description'),
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'permissions',
      header: t('permission-set.permissions'),
      sortable: false,
      render: ({ data: { grantPermissions } }: { data: PermissionSet }) => {
        return (
          <div className={`${gridStyles.badges} ${styles.permissions}`}>
            {grantPermissions?.length ? (
              (grantPermissions || [])
                .sort((p1, p2) => p1.resource.localeCompare(p2.resource))
                .map((permission) => (
                  <Badge bg="primary" key={permission.resource}>
                    <span className={styles.resource}>{capitalize(permission.resource)}</span>
                    <span className={styles.action}>: {getPermissionShortLabel(permission)}</span>
                  </Badge>
                ))
            ) : (
              <Badge key="empty" bg="secondary">
                {t('permission.empty')}
              </Badge>
            )}
          </div>
        );
      },
      filterProps: {
        type: 'select',
        name: 'grantPermissions.resource'
      },
      filterEditor: ResourceSelectFilter,
      filterEditorProps: {
        isMulti: true,
        loadData: async (search?: string) =>
          convertApiResponseToSelectOptions({
            response: await api.permissions.listPermissionDefinitions(
              search
                ? {
                    size: 200,
                    name: `contains:${search}`
                  }
                : {
                    size: 200
                  }
            ),
            idPath: 'resource',
            labelPath: 'resource'
          })
      },
      defaultFlex: 1,
      minWidth: 200,
      height: 100
    }
  ];

  const loadData = async (body: BodyApi) => {
    return api.permissions.listPermissionSets(body);
  };

  return (
    <>
      <DataGrid
        title={t(`permission-set.title`)}
        columns={columns}
        rowHeight={60}
        loadData={loadData}
        resourceName={Resource.PERMISSION_SET}
        RightActions={[
          <Can I="post" a={Resource.PERMISSION_SET} ability={ability} key="createButton">
            <Button
              key="create"
              onClick={() => {
                navigate(getPermissionSetPath({ mode: 'create' }));
              }}
            >
              {t('permission-set.create')}
            </Button>
          </Can>
        ]}
      />
      <Outlet></Outlet>
    </>
  );
};

export default PermissionSetList;
