import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LoadingPage.module.scss';
import Spinner from 'react-bootstrap/Spinner';

const LoadingPage = () => {
  const { t } = useTranslation('i18n');

  return (
    <div className={styles.root}>
      <Spinner animation="border" role="status"></Spinner>
      <div className={styles.label}>{t('status.loading')}...</div>
    </div>
  );
};

export default LoadingPage;
