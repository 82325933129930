import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../context/AppStateContext';

import Select from 'react-select';

import { getNames, getName } from 'i18n-iso-countries';
import { Form } from 'react-bootstrap';
import styles from '../../form/form.module.scss';

type MonoProps = {
  onChange: (value?: string) => void;
  value?: string;
  isMulti: false;
};

type MultiProps = {
  onChange: (value?: string[]) => void;
  value: string[];
  isMulti: true;
};

type Props = (MonoProps | MultiProps) & {
  disabled?: boolean;
  isInvalid?: boolean;
};

type Option = {
  value: string;
  label: string;
};

const getOptions = (lang: string): Option[] => {
  return Object.keys(getNames(lang)).map((countryCode) => ({
    value: countryCode,
    label: getName(countryCode, lang)
  }));
};

const CountrySelect: FC<Props> = ({ onChange, value, isMulti, disabled, isInvalid }) => {
  const { t } = useTranslation('i18n');

  const { state } = useContext(AppStateContext);

  const [options, setOptions] = useState(getOptions(state.language));
  const [selected, setSelected] = useState<Option | Option[]>();

  useEffect(() => {
    setOptions(getOptions(state.language));
  }, [state.language]);

  useEffect(() => {
    setSelected(!value ? undefined : options.find((country) => country.value === value));
  }, [value, options]);

  const handleOnChange = (items?: Option | Option[]) => {
    if (isMulti) {
      onChange(Array.isArray(items) ? items.map((item) => item.value) : items ? [items.value] : []);
    } else if (Array.isArray(items)) {
      onChange(items.length ? items[0].value : undefined);
    } else {
      onChange(items ? items.value : undefined);
    }
  };

  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <Select
        className={isInvalid ? styles.invalid : styles.valid}
        options={options}
        onChange={handleOnChange as any}
        isMulti={isMulti}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
      />
    </>
  );
};

export default CountrySelect;
