import { Access } from '@memnon.orka/orka-sdk';
import { AppStateCtx, PaginableList, Resource } from '../@types/webapp';
import { changeLanguage, getCurrentLanguage } from '../i18n';

export const SET_RESOURCES = 'SET_RESOURCES';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_ACCESS = 'SET_ACCESS';

export type DispatchAction = {
  type: string;
  payload: any;
};
export type DispatchResourceAction = DispatchAction & {
  resourceName: string;
};

export const initialState: AppStateCtx = {
  state: {
    language: getCurrentLanguage(),
    resources: {}
  },
  dispatch: () => {}
};

const setLanguage = (language: string, appState: AppStateCtx): AppStateCtx => {
  localStorage.setItem('language', language);
  changeLanguage(language);
  return {
    ...appState,
    state: { ...appState.state, language: language }
  };
};

const setAccess = (access: Access, globalState: AppStateCtx) => {
  return {
    ...globalState,
    state: { ...globalState.state, access }
  };
};

const setResources = (resourceName: string, resources: PaginableList, state: AppStateCtx): AppStateCtx => {
  return {
    ...state,
    state: {
      ...state.state,
      resources: { ...state.state.resources, [resourceName]: resources }
    }
  };
};
const deleteResource = (
  resourceName: string,
  resource: Partial<Resource>,
  appState: AppStateCtx
): AppStateCtx => {
  const resources = appState.state.resources[resourceName];

  if (!resources?.items?.length) {
    return appState;
  }

  let items = [...resources.items];
  const index = items.findIndex((m) => m.id === resource.id);
  if (index === undefined || index < 0) {
    return appState;
  }
  items.splice(index, 1);
  return {
    ...appState,
    state: {
      ...appState.state,
      resources: {
        ...appState.state.resources,
        [resourceName]: {
          ...appState.state.resources[resourceName],
          items: items,
          size: items.length,
          total: resources.total - 1
        }
      }
    }
  };
};

const addOrUpdateResource = (
  resourceName: string,
  resource: Resource,
  appState: AppStateCtx
): AppStateCtx => {
  const resources = appState.state.resources[resourceName] || {
    from: 0,
    size: 100,
    items: []
  };

  const index = resources.items.findIndex((m) => m.id === resource.id);

  const items = [...resources.items];
  const toAdd = index === undefined || index < 0;
  //Add the new resource
  if (toAdd) {
    items.push(resource);
  }
  //Update existing resource
  else {
    items[index] = resource;
  }

  return {
    ...appState,
    state: {
      ...appState.state,
      resources: {
        ...appState.state.resources,
        [resourceName]: {
          ...resources,
          items: items,
          size: items.length,
          total: toAdd ? resources.total + 1 : resources.total
        }
      }
    }
  };
};

const updateState = (globalState: AppStateCtx, action: DispatchAction | DispatchResourceAction) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LANGUAGE: {
      return setLanguage(payload, globalState);
    }
    case SET_ACCESS: {
      return setAccess(payload, globalState);
    }
    default:
      throw new Error(`[updateState] Dispatch type "${type}" not found`);
  }
};
const updateResourcesState = (globalState: AppStateCtx, action: DispatchResourceAction) => {
  const { type, payload, resourceName } = action;
  switch (type) {
    case SET_RESOURCES: {
      return setResources(resourceName, payload, globalState);
    }
    case ADD_RESOURCE: {
      return addOrUpdateResource(resourceName, payload, globalState);
    }
    case UPDATE_RESOURCE: {
      return addOrUpdateResource(resourceName, payload, globalState);
    }
    case DELETE_RESOURCE: {
      return deleteResource(resourceName, payload, globalState);
    }
    default:
      throw new Error(`[updateResourcesState] Dispatch type "${type}" not found`);
  }
};

const appStateReducer = (state: AppStateCtx, action: DispatchAction | DispatchResourceAction) => {
  if ((action as DispatchResourceAction).resourceName) {
    const newState = updateResourcesState(state, action as DispatchResourceAction);
    return newState;
  }
  const newState = updateState(state, action);
  return newState;
};

export default appStateReducer;
