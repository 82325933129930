import React, { FC, useState, useContext, useEffect } from 'react';
import { ProductionLine } from '@memnon.orka/orka-sdk';

import { Card, Container, Row, Col, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import styles from './ProductionUnitDetails.module.scss';

import { Resource } from '../../tools/resourceTools';
import AppStateContext from '../../context/AppStateContext';
import { getErrorKey } from '../../tools/errorTools';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import LoadingComponent from '../Loading/LoadingComponent';
import { DispatchResourceAction, SET_RESOURCES } from '../../context/AppStateReducer';

import RefreshIcon from '@mui/icons-material/Refresh';
import AbilityContext, { Can } from '../../context/AbilityContext';
import { useAbility } from '@casl/react';
import { useNavigate } from 'react-router-dom';
import { getProductionLinePath } from '../../navigation/AppRoutes';
import ProductionLineCard from './ProductionLineCard';

type Props = {
  productionUnitId: string;
  className?: string;
};

const ProductionLinesLinked: FC<Props> = ({ productionUnitId, className }) => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AppStateContext);
  const [productionLines, setProductionLines] = useState<ProductionLine[]>([]);

  const loadProductionLines = async (productionUnitId: string) => {
    setLoading(true);
    try {
      const response = await api.production.listProductionLines({
        'productionUnit.id': productionUnitId,
        from: 0,
        size: 1000
      });

      response.data.items = response.data?.items?.sort((a, b) => a.order - b.order);

      dispatch({
        payload: response.data,
        resourceName: Resource.PRODUCTION_UNIT_LINES,
        type: SET_RESOURCES
      } as DispatchResourceAction);
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadProductionLines(productionUnitId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionUnitId]);

  useEffect(() => {
    setProductionLines((state.resources[Resource.PRODUCTION_UNIT_LINES]?.items as any) || []);
  }, [state.resources]);

  return (
    <div className={className}>
      <Card>
        <Card.Header className={styles.header}>
          <div className={styles.headerTitle}>{t('production-line.title')}</div>
          <div className={styles.headerBtn}>
            <Button
              key="refresh"
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                loadProductionLines(productionUnitId);
              }}
            >
              <RefreshIcon />
            </Button>

            <Can I="post" a={Resource.PRODUCTION_LINE} ability={ability} key="createButton">
              <Button
                key="create"
                variant="outline-secondary"
                size="sm"
                disabled={loading}
                onClick={() => {
                  navigate(getProductionLinePath({ mode: 'create', productionUnitId }));
                }}
              >
                {t('production-line.create')}
              </Button>
            </Can>
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <LoadingComponent size="sm" />
          ) : productionLines.length ? (
            <div>
              {productionLines.map((productionLine) => (
                <ProductionLineCard
                  className={className}
                  productionLine={productionLine}
                  key={productionLine.id}
                />
              ))}
            </div>
          ) : (
            <Container className={styles.container}>
              <Row className={styles.row}>
                <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}></Col>
                <Col fluid className={styles.value}>
                  {t('common.empty')}
                </Col>
              </Row>
            </Container>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductionLinesLinked;
