import React, { FC, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, PermissionDefinition } from '@memnon.orka/orka-sdk';
import { UtilRequiredKeys } from '../@types/webapp';
import styles from './PermissionsInput.module.scss';
import formStyles from '../form/form.module.scss';
import AppStateContext from '../context/AppStateContext';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';
import { DispatchResourceAction, SET_RESOURCES } from '../context/AppStateReducer';
import { getErrorKey } from '../tools/errorTools';
import { toast } from 'react-toastify';
import { Button, Card, Form } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import { uniq } from 'lodash';
import { capitalize } from '@mui/material';
import LoadingComponent from '../components/Loading/LoadingComponent';
import Select from 'react-select';

const MANAGE = 'manage';

const ACTIONS_TRANSLATED = ['delete', 'get', 'manage', 'post', 'put'];

type Props = {
  onChange: (permissions: Permission[]) => void;
  value: UtilRequiredKeys<Permission, 'resource'>[];
  disabled?: boolean;
};

const PermissionCard = ({
  permission,
  onDelete,
  onChange,
  ...props
}: {
  className?: string;
  permission: Permission;
  onDelete: () => void;
  onChange: (permission: Permission) => void;
}) => {
  const { state } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const [definition, setDefinition] = useState<PermissionDefinition | undefined>();
  const [actions, setActions] = useState<string[]>([]);

  useEffect(() => {
    setDefinition(
      state.resources?.[Resource.PERMISSION_DEFINITION]?.items?.find(
        (p) => p.resource === permission.resource
      ) as PermissionDefinition | undefined
    );
  }, [permission.resource, state.resources]);

  useEffect(() => {
    let actions: string[] = [];
    if (definition) {
      actions = actions.concat(definition.actions);
    }
    actions = actions.concat(permission.actions);

    setActions(uniq([MANAGE, ...actions]));
  }, [permission.actions, definition]);

  const isSelected = (action: string) => {
    return permission.actions.some((a) => a === action || a === MANAGE);
  };
  const handleSelectChanged = (action: string, value: boolean) => {
    const managePresent = permission.actions.some((a) => a === MANAGE);

    let actions = [...permission.actions];
    if (!value && action === MANAGE) {
      actions = [];
    } else if (!value && managePresent) {
      actions = definition?.actions.filter((a) => a !== MANAGE && a !== action) || [];
    } else if (!value) {
      actions = actions.filter((a) => a !== action);
    } else if (action === MANAGE) {
      actions = [MANAGE];
    } else if (action) {
      actions.push(action);
      actions = uniq(actions);
    }

    onChange({ ...permission, actions });
  };

  return (
    <div {...props}>
      <Card>
        <Card.Body className={styles.cardBody}>
          <div className={styles.actions}>
            <Button variant="outline-secondary" size="sm" onClick={onDelete}>
              <DeleteIcon />
            </Button>
          </div>
          <div className={styles.container}>
            <div className={styles.title}>{capitalize(permission.resource)}</div>
            <div className={styles.checkboxes}>
              {actions.map((action) => {
                const checked = isSelected(action);

                return (
                  <Form.Check
                    className={formStyles.checkbox}
                    id={`${permission.resource}-${action}`}
                    label={
                      ACTIONS_TRANSLATED.some((a) => a === action)
                        ? t(`permission.action.${action}`)
                        : capitalize(action)
                    }
                    checked={checked}
                    onChange={() => handleSelectChanged(action, !checked)}
                  />
                );
              })}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

const PermissionsInput: FC<Props> = ({ onChange, disabled, value }) => {
  const { t } = useTranslation('i18n');

  const { dispatch, state } = useContext(AppStateContext);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<{ value: string; label: any }[]>([]);

  useEffect(() => {
    let permissionDefinitions =
      (state.resources?.[Resource.PERMISSION_DEFINITION]?.items as PermissionDefinition[]) || [];

    permissionDefinitions = permissionDefinitions.filter(
      (p) => !value.some((v) => v.resource === p.resource)
    );

    const options = permissionDefinitions.map((permission) => ({
      value: permission.resource,
      label: capitalize(permission.resource)
    }));

    setOptions(options);
  }, [state.resources, value]);

  const handleLoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.permissions.listPermissionDefinitions({
        from: 0,
        size: 1000,
        sortBy: 'asc:resource'
      });
      dispatch({
        payload: data,
        resourceName: Resource.PERMISSION_DEFINITION,
        type: SET_RESOURCES
      } as DispatchResourceAction);
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!state.resources?.[Resource.PERMISSION_DEFINITION]?.items) {
      handleLoadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (permission: Permission) => {
    const index = value.findIndex((s) => s.resource === permission.resource);
    if (index < 0) {
      return;
    }

    const newValue = [...value];
    newValue[index] = permission;
    onChange(newValue);
  };
  const handleOnDelete = (resource: string) => {
    let newValue = value.filter((s) => s.resource !== resource);
    onChange(newValue);
  };

  const handleOnPermissionSelected = (selection: undefined | { value: string }) => {
    if (selection) {
      onChange([...value, { actions: [], resource: selection.value }]);
    }
  };

  return (
    <div className={styles.root}>
      {loading ? (
        <LoadingComponent size="sm" />
      ) : (
        <div>
          <Select
            className={styles.select}
            options={options}
            onChange={handleOnPermissionSelected as any}
            isMulti={false}
            value={{
              value: '',
              label: <span className={styles.defaultValue}>{t('permission-set.addPermission')}</span>
            }}
            isDisabled={disabled}
            noOptionsMessage={() => t('common.noValue')}
            placeholder=""
          />
          {value.map((permission) => (
            <PermissionCard
              className={styles.item}
              key={permission.resource}
              permission={permission}
              onDelete={() => handleOnDelete(permission.resource)}
              onChange={(permission) => handleOnChange(permission)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PermissionsInput;
