import React from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from '../datagrid/DataGrid';
import api from '../api/Api';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Can, useAbility } from '@casl/react';

import { Outlet } from 'react-router-dom';
import { BodyApi, DataGridColumn } from '../@types/webapp';
import { getProductionLinePath, getProductionUnitPath } from '../navigation/AppRoutes';
import AbilityContext from '../context/AbilityContext';
import { Resource } from '../tools/resourceTools';
import { DEFAULT_TEXT_DELAY } from '../datagrid/datagridTools';
import { Link } from 'react-router-dom';
import SiteLabel from '../components/Production/SiteLabel';
import { ProductionLine } from '@memnon.orka/orka-sdk';
import ResourceSelectFilter from '../components/Select/ResourceSelectFilter';
import { convertApiResponseToSelectOptions } from '../components/Select/selectTools';

const ProductionLineList = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const location = useLocation();

  const navigate = useNavigate();

  const columns: DataGridColumn[] = [
    {
      name: 'name',
      sortable: true,
      header: t('production-line.name'),
      render: ({ data }: { data: ProductionLine }) => {
        return (
          <Link to={getProductionLinePath({ mode: 'update', productionLineId: data.id })}>
            {data.name || t('common.empty')}
          </Link>
        );
      },
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY,
      defaultFlex: 1,
      minWidth: 100
    },
    {
      name: 'productionUnit.name',
      header: t('production-line.productionUnit'),
      render: ({ data: { productionUnit } }: { data: ProductionLine }) => {
        return (
          <Link to={getProductionUnitPath({ mode: 'get', productionUnitId: productionUnit.id })}>
            {productionUnit.name || t('common.empty')}
          </Link>
        );
      },
      filterProps: {
        type: 'text'
      },
      sortable: true,
      filterDelay: DEFAULT_TEXT_DELAY
    },

    {
      name: 'siteId',
      sortable: true,
      header: t('production-line.site'),
      render: ({ data: { productionUnit } }: { data: ProductionLine }) => {
        return <SiteLabel siteId={productionUnit.siteId} />;
      },
      filterProps: {
        type: 'select',
        name: 'siteId',
        value: location.state?.siteId,
        _source: location.state?.siteId
          ? { value: location.state.siteId, label: location.state.siteId }
          : undefined
      },
      filterEditor: ResourceSelectFilter,
      filterEditorProps: {
        isMulti: true,
        loadData: async (search?: string) =>
          convertApiResponseToSelectOptions({
            response: await api.sites.listSites(
              search
                ? {
                    size: 200,
                    name: `contains:${search}`
                  }
                : {
                    size: 200
                  }
            ),
            idPath: 'id',
            labelPath: 'id'
          })
      },
      defaultWidth: 200
    }
  ];

  const loadData = async (body: BodyApi) => {
    return api.production.listProductionLines({ ...body });
  };

  return (
    <>
      <DataGrid
        title={t(`production-line.title`)}
        columns={columns}
        loadData={loadData}
        resourceName={Resource.PRODUCTION_LINE}
        RightActions={[
          <Can I="post" a={Resource.PRODUCTION_LINE} ability={ability} key="createButton">
            <Button
              key="create"
              onClick={() => {
                navigate(getProductionLinePath({ mode: 'create' }));
              }}
            >
              {t('production-line.create')}
            </Button>
          </Can>
        ]}
      />
      <Outlet></Outlet>
    </>
  );
};

export default ProductionLineList;
