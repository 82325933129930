import React from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from '../datagrid/DataGrid';
import api from '../api/Api';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Can, useAbility } from '@casl/react';

import { Link, Outlet } from 'react-router-dom';
import { BodyApi, DataGridColumn } from '../@types/webapp';
import { getSitePath } from '../navigation/AppRoutes';
import AbilityContext from '../context/AbilityContext';
import { Resource } from '../tools/resourceTools';
import { DEFAULT_TEXT_DELAY } from '../datagrid/datagridTools';
import Country from '../components/Country/Country';
import CountryFilter from '../components/Select/CountryFilter';

const SiteList = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const location = useLocation();

  const navigate = useNavigate();

  const columns: DataGridColumn[] = [
    {
      name: 'id',
      header: t('site.id'),
      render: ({ data }: any) => {
        return <Link to={getSitePath({ mode: 'update', siteId: data.id })}>{data.id}</Link>;
      },
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'country',
      header: t('member.country'),
      render: ({ data }: any) => {
        return <Country countryCode={data.country} />;
      },
      filterProps: {
        type: 'select',
        name: 'country',
        value: location.state?.country,
        _source: location.state?.country
      },
      filterEditor: CountryFilter,
      filterEditorProps: {
        isMulti: true
      },

      filterDelay: DEFAULT_TEXT_DELAY,
      defaultWidth: 200
    },
    {
      name: 'city',
      header: t('site.city'),
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'operatedBy',
      header: t('site.operatedBy'),
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY,
      minWidth: 100,
      defaultFlex: 1
    }
  ];

  const loadData = async (body: BodyApi) => {
    return api.sites.listSites(body);
  };

  return (
    <>
      <DataGrid
        title={t(`site.title`)}
        columns={columns}
        loadData={loadData}
        resourceName={Resource.SITE}
        RightActions={[
          <Can I="post" a={Resource.SITE} ability={ability} key="createButton">
            <Button
              key="create"
              onClick={() => {
                navigate(getSitePath({ mode: 'create' }));
              }}
            >
              {t('site.create')}
            </Button>
          </Can>
        ]}
      />
      <Outlet></Outlet>
    </>
  );
};

export default SiteList;
