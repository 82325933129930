import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../dialog/DefaultCrudDialog';
import RoleForm from './RoleForm';
import { useNavigate, useParams } from 'react-router-dom';
import { getRolePath } from '../navigation/AppRoutes';
import { Role, RolePost, RolePut } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import AppStateContext from '../context/AppStateContext';
import {
  ADD_RESOURCE,
  DELETE_RESOURCE,
  DispatchResourceAction,
  UPDATE_RESOURCE
} from '../context/AppStateReducer';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';

const RoleDialog = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();

  const ref = useRef();

  const { roleId } = useParams();

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [role, setRole] = useState<Partial<Role>>();
  const [existingRole, setExistingRole] = useState<Role>();
  const [memberCount, setMemberCount] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    navigate(getRolePath({ mode: 'list' }));
  };

  const handleLoadRole = async (roleId: string) => {
    setLoading(true);

    try {
      const { data } = await api.roles.getRole(roleId);
      setExistingRole(data);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (roleId) {
      handleLoadRole(roleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId]);

  const handleSave = async () => {
    if (!(ref?.current as any)?.isValid()) {
      return false;
    }

    setSaving(true);
    try {
      if (roleId) {
        const { data: roleUpdated } = await api.roles.updateRole(roleId, role as RolePut);
        dispatch({
          payload: roleUpdated,
          resourceName: Resource.ROLE,
          type: UPDATE_RESOURCE
        } as DispatchResourceAction);
        toast.success(t(`role.success.update`, { name: roleUpdated.name }));
      } else {
        const { data: roleCreated } = await api.roles.createRole(role as RolePost);
        dispatch({
          payload: roleCreated,
          resourceName: Resource.ROLE,
          type: ADD_RESOURCE
        } as DispatchResourceAction);
        toast.success(t(`role.success.create`, { name: roleCreated.name }));
      }
      setSaving(false);
      return true;
    } catch (error) {
      setSaving(false);
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return false;
    }
  };
  const handleDelete = async () => {
    try {
      if (roleId) {
        await api.roles.deleteRole(roleId);
        dispatch({
          payload: {
            id: roleId
          },
          resourceName: Resource.ROLE,
          type: DELETE_RESOURCE
        } as DispatchResourceAction);
        toast.success(t(`role.success.delete`));
      }
      setSaving(false);
      return true;
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return false;
    }
  };

  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: 'post',
        resource: Resource.ROLE
      }}
      title={roleId ? t(`role.update`) : t(`role.create`)}
      closeLabel={t('action.cancel')}
      saveLabel={roleId ? t(`action.update`) : t(`action.create`)}
      onClose={handleClose}
      saving={saving}
      onDelete={roleId && role ? handleDelete : undefined}
      onSave={handleSave}
      deleteConfirmLabel={
        <div
          dangerouslySetInnerHTML={{
            __html: t('role.confirmDelete', { count: memberCount, name: role?.name })
          }}
        />
      }
      loading={loading}
    >
      {existingRole ? (
        <RoleForm
          ref={ref}
          mode="update"
          role={existingRole}
          onChange={setRole}
          disabled={saving}
          onMemberCountChange={setMemberCount}
        />
      ) : (
        <RoleForm ref={ref} mode="create" onChange={setRole} disabled={saving} />
      )}
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default RoleDialog;
