import React, { useState, FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getStateFromUrl, removeStateFromUrl } from './datagrid/datagridTools';

const ConvertExternalState: FC<any> = ({ children }) => {
  const location = useLocation();

  const [initialFilterState] = useState(getStateFromUrl(location));

  return !initialFilterState || !Object.keys(initialFilterState).length ? (
    children
  ) : (
    <Navigate to={removeStateFromUrl(location)} state={initialFilterState} replace />
  );
};

export default ConvertExternalState;
