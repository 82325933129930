import React, { FC } from 'react';
import styles from './Page.module.scss';

type Props = { className?: string };

const Page: FC<Props> = ({ children, className, ...props }) => {
  return (
    <div className={`${styles.page} ${className}`} {...props}>
      <div className={`${styles.root} ${className}`} {...props}>
        {children}
      </div>
    </div>
  );
};

export default Page;
