import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../dialog/DefaultCrudDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetPath } from '../navigation/AppRoutes';
import { Asset, AssetPost, AssetPut } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import AppStateContext from '../context/AppStateContext';
import { ADD_RESOURCE, DispatchResourceAction, UPDATE_RESOURCE } from '../context/AppStateReducer';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';
import AssetForm from './AssetForm';
import { pick } from 'lodash';

const AssetDialog = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();

  const ref = useRef();

  const { assetId } = useParams();

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [existingAsset, setExistingAsset] = useState<Asset>();
  const [updatedAsset, setUpdatedAsset] = useState<Partial<Asset>>({});
  const [loading, setLoading] = useState(false);

  const handleLoadAsset = async (assetId: string) => {
    setLoading(true);

    try {
      const { data } = await api.assets.getAsset(assetId);
      setExistingAsset(data);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (assetId) {
      handleLoadAsset(assetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const handleClose = () => {
    setShowModal(false);
    navigate(getAssetPath({ mode: 'list' }));
  };

  const handleCreate = async (asset: AssetPost) => {
    try {
      const { data: assetCreated } = await api.assets.createAsset(asset as AssetPost);
      dispatch({
        payload: assetCreated,
        resourceName: Resource.ASSET,
        type: ADD_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`asset.success.create`, { name: assetCreated.reference?.productionId || '' }));
      return assetCreated;
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });

      return undefined;
    }
  };

  const handleUpdate = async (assetId: string, asset: AssetPut) => {
    try {
      const body = pick(
        asset,
        'siteId',
        'characteristic',
        'comments',
        'reference',
        'category',
        'remoteControlProtocol',
        'applicationProtocols',
        'isrPort',
        'firewireDeviceId',
        'ethernetMacAddress',
        'head',
        'ports',
        'ingestChannels'
      ) as AssetPut;
      const { data: assetUpdated } = await api.assets.updateAsset(assetId, body);
      dispatch({
        payload: assetUpdated,
        resourceName: Resource.ASSET,
        type: UPDATE_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`asset.success.update`, { name: assetUpdated.reference?.productionId || '' }));
      return assetUpdated;
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return undefined;
    }
  };

  const handleSave = async () => {
    if (!(ref?.current as any)?.isValid()) {
      return false;
    }

    setSaving(true);
    let response: Asset | undefined;
    if (assetId) {
      response = await handleUpdate(assetId, updatedAsset as AssetPut);
    } else {
      response = await handleCreate(updatedAsset as AssetPost);
    }
    setSaving(false);

    return !!response;
  };

  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: assetId ? 'put' : 'post',
        resource: Resource.ASSET
      }}
      title={assetId ? t(`asset.update`) : t(`asset.create`)}
      closeLabel={t('action.cancel')}
      saveLabel={assetId ? t(`action.update`) : t(`action.create`)}
      onClose={handleClose}
      saving={saving}
      onSave={handleSave}
      loading={loading}
    >
      {existingAsset ? (
        <AssetForm
          ref={ref}
          mode="update"
          asset={existingAsset}
          onChange={setUpdatedAsset}
          disabled={saving}
        />
      ) : (
        <AssetForm ref={ref} mode="create" onChange={setUpdatedAsset} disabled={saving} />
      )}
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default AssetDialog;
