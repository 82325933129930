const dico = {
  title: 'ORKA console',
  action: {
    logout: 'Logout',
    create: 'Create',
    update: 'Edit',
    delete: 'Delete',
    close: 'Close',
    save: 'Save',
    cancel: 'Cancel',
    confirm: 'Confirm',
    add: 'Add',
    addValue: 'Add {{value}}',
  },
  status: {
    loading: 'Loading',
  },
  common: {
    noValue: "No data",
    empty: "[empty]"
  },
  error: {
    notAllowed: "Unauthorised access",
    forbidden: "You are not allowed to do this action, please refresh your page",
    unknown: "An error occurs, please retry later",
  },
  member: {
    title: 'Members',
    email: 'Email',
    lastName: 'Lastname',
    firstName: 'Firstname',
    phone: 'Phone',
    country: 'Country',
    language: 'Language',
    languageValues: {
      "fr": "Français",
      "en": "English",
    },
    roles: 'Roles',
    pronouns: "Pronouns",
    pronounsValues: {
      "he/him": 'He/Him',
      "she/her": 'Sher/Her',
      "they/them": 'They/Them'
    },

    create: 'Create Member',
    update: 'Update Member',
    delete: 'Delete Member',

    confirmDelete: 'Are you sure you want to delete the Member <b>{{name}}</b>?',

    success: {
      create: "The Member {{name}} has been successfully created.",
      update: "The Member {{name}} has been successfully updated.",
      delete: "The Member has been successfully deleted."
    },
    error: {
      notUnique: "A Member with the email {{email}} already exists, please change it.",
      phone: "The phone number must begin with \"+\" followed by the country prefix and the number.",
      email: "The email is not valid",
    }
  },
  role: {
    title: 'Roles',
    name: 'Name',
    description: 'Description',
    permissionSets: 'Permissions sets',
    empty: "None",

    linkedMembers: "Linked members",
    members_zero: "{{count}} member",
    members_one: "{{count}} member",
    members_other: "{{count}} members",

    create: 'Create Role',
    update: 'Update Role',
    delete: 'Delete Role',

    confirmDelete_zero: 'Are you sure you want to delete the Role <b>{{name}}</b>?',
    confirmDelete_one: 'Are you sure you want to delete the Role <b>{{name}}</b>? <br/><br/><b>{{count}}</b> member is linked to this Role and will loose the related permissions',
    confirmDelete_other: 'Are you sure you want to delete the Role <b>{{name}}</b>? <br/><br/><b>{{count}}</b> members are linked to this Role and will loose the related permissions',


    success: {
      create: "The Role {{name}} has been successfully created.",
      update: "The Role {{name}} has been successfully updated.",
      delete: "The Role has been successfully deleted."
    }
  },
  "permission-set": {
    title: 'PermissionSets',
    name: 'Name',
    description: 'Description',
    permissions: 'Permissions',
    empty: "None",

    addPermission: "Click here to add a new permission",

    create: 'Create PermissionSet',
    update: 'Update PermissionSet',
    delete: 'Delete PermissionSet',

    linkedRoles: "Roles linked",
    roles_zero: "{{count}} role",
    roles_one: "{{count}} role",
    roles_other: "{{count}} roles",

    success: {
      create: "The PermissionSet {{name}} has been successfully created.",
      update: "The PermissionSet {{name}} has been successfully updated.",
      delete: "The PermissionSet has been successfully deleted."
    },
    confirmDelete_zero: 'Are you sure you want to delete the PermissionSet <b>{{name}}</b>?',
    confirmDelete_one: 'Are you sure you want to delete the PermissionSet <b>{{name}}</b>? <br/><br/><b>{{count}}</b> role is linked to this PermissionSet and will loose the related permissions',
    confirmDelete_other: 'Are you sure you want to delete the PermissionSet <b>{{name}}</b>? <br/><br/><b>{{count}}</b> roles are linked to this PermissionSet and will loose the related permissions',

  },
  site: {
    title: 'Production Sites',
    country: 'Country',
    city: 'City',
    timezone: 'Timezone',
    id: "ID",
    operatedBy: "Operated by",
    other: "Other",

    create: 'Create Production Site',
    update: 'Update Production Site',
    delete: 'Delete Production Site',

    confirmDelete: 'Are you sure you want to delete the Production Site <b>{{id}}</b>?',

    success: {
      create: "The Production Site {{id}} has been successfully created.",
      update: "The Production Site {{id}} has been successfully updated.",
      delete: "The Production Site has been successfully deleted."
    },
    error: {
      notUnique: "The Production Site {{id}} already exists, please change the ID.",
    }
  },
  "production-line": {
    title: 'Production Lines',

    name: "Name",
    order: "Order",
    site: "Production Site",
    productionUnit: "Production Unit",

    create: 'Create Production Line',
    update: 'Update Production Line',
    delete: 'Delete Production Line',

    details: "Details",

    confirmDelete: 'Are you sure you want to delete the Production Line <b>{{name}}</b>?',

    success: {
      create: "The Production Line {{name}} has been successfully created.",
      update: "The Production Line {{name}} has been successfully updated.",
      delete: "The Production Line has been successfully deleted."
    },
    error: {
    }
  },
  "production-unit": {
    title: 'Production Units',
    name: 'Name',
    site: 'Production Site',
    type: 'Type',
    typeValues: {
      audio: "Audio",
      video: "Video",
      film: "Film",
    },
    lineCount: '# Lines',
    associate: {
      robot: "Associate/Dissociate Robot",
      workStation: "Associate/Dissociate Workstation",
    },
    saveAsset: {
      robot: "Associate Robot",
      workStation: "Associate Workstation"
    },
    removeAsset: {
      robot: "Dissociate Robot",
      workStation: "Dissociate Workstation"
    },
    showAll: {
      robot: "Include associated Robots",
      workStation: "Include associated Workstations",
    },

    create: 'Create Production Unit',
    update: 'Update Production Unit',
    delete: 'Delete Production Unit',

    details: "Details",

    confirmDelete: 'Are you sure you want to delete the Production Unit <b>{{name}}</b>?',

    success: {
      create: "The Production Unit {{name}} has been successfully created.",
      update: "The Production Unit {{name}} has been successfully updated.",
      delete: "The Production Unit has been successfully deleted."
    },
    error: {
    }
  },
  "asset": {
    title: 'Assets',
    site: 'Production Site',
    brand: 'Brand',
    model: 'Model',
    serial: 'Serial number',
    port: 'Port',
    comments: 'Comments',
    type: 'Type',
    category: 'Category',
    status: 'Status',
    assignedToProductionLine: 'Production Line',
    assignedToProductionUnit: 'Production Unit',
    assignedToAsset: 'Asset',
    associated: 'Associated',
    notAssociated: 'Not associated',

    remoteControlProtocol: "Remote Control Protocol",
    isrPort: "ISR Port",
    applicationProtocols: "Application Protocols",
    firewireDeviceId: "Firewire Device Id",
    ethernetMacAddress: "Ethernet Mac Address",
    ports: "Ports",
    ingestChannels: "Ingest Channels",
    head: "Head",

    locationReadOnly: "Only editable for inactive assets",

    reference: {
      inventoryId: 'Inventory',
      productionId: 'Production ID',
      custom1: 'Custom 1',
      custom2: 'Custom 2',
      custom3: 'Custom 3',
    },

    sections: {
      reference: "References",
      characteristic: "Characteristics",
      location: "Location",
      properties: "Properties",
      status: "Status",
      components: "Components",
    },

    create: 'Create Asset',
    update: 'Update Asset',
    delete: 'Delete Asset',

    details: "Details",

    confirmDelete: 'Are you sure you want to delete the Asset <b>{{name}}</b>?',

    success: {
      create: "The Asset {{name}} has been successfully created.",
      update: "The Asset {{name}} has been successfully updated.",
      delete: "The Asset has been successfully deleted."
    },
    error: {
    }
  },
  nodeRed: {
    title: "Node Red"
  },
  amplify: {
    'Sign In': 'Sign in',
    'Sign Up': 'Register now',
    'Sign Out': 'Sign out',
    'Forgot Password': 'Forgot Password',
    Username: 'Username',
    Password: 'Password',
    'Change Password': 'Change Password',
    'New Password': 'New Password',
    Email: 'Email',
    'Phone Number': 'Phone number',
    'Confirm Password': 'Confirm Password',
    'Confirm a Code': 'Confirm a code',
    'Confirm Sign In': 'Confirm sign in',
    'Confirm Sign Up': 'Confirm registration',
    'Back to Sign In': 'Back to Sign in',
    'Send Code': 'Send code',
    Confirm: 'Confirm',
    'Resend a Code': 'Resend code',
    Submit: 'Submit',
    Skip: 'Skip',
    Verify: 'Verify',
    'Verify Contact': 'Verify contact',
    Code: 'Code',
    'Account recovery requires verified contact information':
      'Account recovery requires verified contact information',
    'User does not exist': 'The user does not exist',
    'User already exists': 'User already exists',
    'Incorrect username or password': 'Incorrect username or password',
    'Invalid password format': 'invalid password format',
    'Invalid phone number format':
      'Invalid phone number format.\nPlease use a phone number format of +45678900',
    'Sign in to your account': 'Sign in to your account',
    'Forget your password? ': 'Forgot your password ? ',
    'Reset password': 'Reset your password',
    'No account? ': 'Not registered yet ? ',
    'Create account': 'Create an account',
    'Create Account': 'Create an account',
    'Have an account? ': 'Already have an account ? ',
    'Sign in': 'Sign in',
    'Create a new account': 'Create a new account',
    'Reset your password': 'Reset your password',
    'Enter your username': 'Enter your username',
    'Enter your password': 'Enter your password',
    'An account with the given email already exists.':
      'A user with this email address already exists.',
    'Username cannot be empty': 'Username must be provided.',
    Given_name: 'First name',
    Name: 'Last name',
    Phone_number: 'Phone number',
    'Forgot your password? ': 'Forgot Password ? ',
    'Enter your email': 'Enter your email',
  },
  datagrid: {
    // Custom translations
    'filters': 'Filters',
    // Column Options
    'sortAsc': 'Sort ascending',
    'sortDesc': 'Sort descending',
    'unsort': 'Unsort',
    'columns': 'Columns',
    'showFilteringRow': 'Show filtering row',
    'hideFilteringRow': 'Hide filtering row',

    // Filtering options
    'contains': 'Contains',
    'eq': 'Equals',
    'startsWith': 'Starts with',
    'endsWith': 'Ends with',
    'enable': 'Enable',
    'disable': 'Disable',
    'clear': 'Clear',
    'clearAll': 'Clear all',

    // Footer
    'pageText': 'Page',
    'perPageText': 'Results per page',
    'showingText': 'Showing',
    'ofText': ' of ',
  },
  sidemenu: {
    'home': 'Home',
    'administration': 'Administration',
    'configuration': 'Configuration',
  },
  permission: {
    'title': 'Permissions',
    'empty': 'None',
    'action': {
      "manage": "All",
      "put": "Update",
      "post": "Create",
      "delete": "Delete",
      "get": "Read",
    },
  },
  form: {
    error: {
      required: "This field is required",
      "not-number": "This field have to be a number",
    }
  }
};
export default dico;
