import React, { FC } from 'react';
import { ProductionLine } from '@memnon.orka/orka-sdk';

import { Card, Button, Container, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import stylesSpec from './ProductionLineCard.module.scss';
import stylesCommon from './ProductionUnitDetails.module.scss';
import formStyles from '../../form/form.module.scss';

import { Resource } from '../../tools/resourceTools';

import AbilityContext, { Can } from '../../context/AbilityContext';
import { useAbility } from '@casl/react';
import { useNavigate } from 'react-router-dom';
import { getProductionLinePath } from '../../navigation/AppRoutes';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AssetTypeLabel from './AssetTypeLabel';

const styles = { ...stylesCommon, ...stylesSpec, ...formStyles };

type Props = {
  productionLine: ProductionLine;
  className?: string;
};

const ProductionLineCard: FC<Props> = ({ productionLine, className }) => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();

  const handleUpdateProductionLine = () => {
    navigate(
      getProductionLinePath({
        mode: 'update',
        productionLineId: productionLine.id,
        productionUnitId: productionLine.productionUnit.id
      })
    );
  };
  return (
    <div className={className}>
      <Card>
        <Card.Body className={styles.cardBody}>
          <div className={styles.actions}>
            <Can I="put" a={Resource.PRODUCTION_LINE} ability={ability} key="updateButton">
              <Button variant="outline-secondary" size="sm" onClick={handleUpdateProductionLine}>
                <ModeEditIcon />
              </Button>
            </Can>
          </div>
          <Container className={styles.container}>
            <Row className={styles.row}>
              <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={`${styles.field}`}>
                {t('production-line.order')}
              </Col>
              <Col fluid className={`${styles.value} ${styles.empty}`}>
                {productionLine.order}
              </Col>
            </Row>
            <Row className={styles.row}>
              <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={`${styles.field}`}>
                <AssetTypeLabel type="player" />
              </Col>
              <Col fluid className={`${styles.value} ${styles.empty}`}>
                {t('common.empty')}
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductionLineCard;
