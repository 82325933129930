import { I18n, Amplify } from 'aws-amplify';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { registerLocale } from "i18n-iso-countries"

import en from './locale/en';
import fr from './locale/fr';

const DEFAULT_NS = 'i18n';

const defaultLanguage = window.localStorage.getItem('lang') || 'en';

I18n.setLanguage(defaultLanguage);
const dict = {
  en: en.amplify,
  fr: fr.amplify,
};
I18n.putVocabularies(dict);

registerLocale(require("i18n-iso-countries/langs/en.json"));
registerLocale(require("i18n-iso-countries/langs/fr.json"));

i18next.use(initReactI18next).init({
  defaultNS: DEFAULT_NS,
  resources: {
    en: {
      [DEFAULT_NS]: en,
    },
    fr: {
      [DEFAULT_NS]: fr,
    },
  },
  lng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
  detection: {},
});

export const getAvailableLanguages = () => {
  return Object.keys(dict);
};

export const changeLanguage = (language: string) => {
  Amplify.I18n.setLanguage(language);
  i18next.changeLanguage(language);
  window.localStorage.setItem('lang', language);
};

export const getCurrentLanguage = () => {
  return i18next.language;
}

const reactdatagridDict: Record<string, any> = {
  en: en.datagrid,
  fr: fr.datagrid,
};

export const getTranslationsDataGrid = (language: string) => {
  return reactdatagridDict[language] || reactdatagridDict[defaultLanguage];
}

export default i18next;
