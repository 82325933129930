import React, { FC, forwardRef, useEffect, useState, useImperativeHandle, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductionLine, ProductionUnitRef } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import ProductionUnitSelect from '../components/Select/ProductionUnitSelect';
import AppStateContext from '../context/AppStateContext';
import { Resource } from '../tools/resourceTools';

type UpdateProductionLineProps = FormProps<ProductionLine> & {
  mode: 'update';
  productionLine: ProductionLine;
  productionUnitId?: undefined;
};

type CreateProductionLineProps = FormProps<ProductionLine> & {
  mode: 'create';
  //Property not relevent for creation but only to simplify the code
  productionLine?: undefined;
  productionUnitId?: string;
};

const ProductionLineForm: FC<CreateProductionLineProps | UpdateProductionLineProps> = forwardRef(
  ({ onChange, disabled, productionLine, productionUnitId, mode }, ref) => {
    const { t } = useTranslation('i18n');
    const { state } = useContext(AppStateContext);

    const [data, setData] = useState<Partial<ProductionLine>>({});
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [defaultProductionUnit, setDefaultProductionUnit] = useState<ProductionUnitRef>();

    useEffect(() => {
      setData(productionLine || {});
      setDefaultProductionUnit(productionLine?.productionUnit);
    }, [productionLine]);

    useEffect(() => {
      if (productionLine) {
        return;
      }

      const defaultValue = state.resources[Resource.PRODUCTION_UNIT]?.items?.find(
        (pu) => pu.id === productionUnitId
      ) as any;
      setDefaultProductionUnit(defaultValue);

      if (!data.productionUnit) {
        handleProductionUnitChange(defaultValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productionLine, productionUnitId, state.resources]);

    useImperativeHandle(ref, () => ({
      isValid() {
        const errors: Record<string, string> = {};

        if (!data.productionUnit) {
          errors.productionUnit = t('form.error.required');
        }

        setErrors(errors);
        return !Object.keys(errors).length;
      }
    }));

    const disableComp = disabled;

    const cleanError = (field: string) => {
      const { [field]: fieldError, ...otherErrors } = errors;
      if (fieldError) {
        //Clean the error
        setErrors(otherErrors);
      }
    };

    const handleProductionUnitChange = (productionUnit: ProductionUnitRef | undefined) => {
      const updated = { ...data, productionUnit };
      cleanError('productionUnit');
      setData(updated);
      onChange(updated);
    };

    return (
      <Container>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('production-line.productionUnit')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <ProductionUnitSelect
              key={`ProductionUnitSelect-${defaultProductionUnit?.id}`}
              onChange={handleProductionUnitChange}
              isInvalid={!!errors.productionUnit}
              disabled={disableComp || !!productionUnitId || mode === 'update'}
              value={data.productionUnit}
              defaultValue={data.productionUnit || defaultProductionUnit}
            />
            <Form.Control.Feedback type="invalid">{errors.productionUnitId}</Form.Control.Feedback>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default ProductionLineForm;
