import BlockIcon from '@mui/icons-material/BlockOutlined';
import styles from './NotAllowed.module.scss';
import { useTranslation } from 'react-i18next';

const NotAllowed = () => {
  const { t } = useTranslation('i18n');
  return (
    <div className={styles.error}>
      <span>
        <BlockIcon />
        {t('error.notAllowed')}
      </span>
    </div>
  );
};

export default NotAllowed;
