import React, { FC, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import { FilterValue } from '../@types/webapp';
import { TYPES } from './ProductionUnitTypeSelect';

type Props = {
  onChange: (value: FilterValue) => {};
  filterValue: FilterValue;
  isMulti?: boolean;
};

const ProductionUnitTypeSelectFilter: FC<Props> = forwardRef(({ onChange, filterValue, isMulti }, ref) => {
  const { t } = useTranslation('i18n');
  const [options] = useState(
    TYPES.map((type) => ({
      value: type,
      label: t(`production-unit.typeValues.${type}`)
    }))
  );

  const handleOnChange = (items: any) => {
    const values = items ? (Array.isArray(items) ? items : [items]) : [];
    onChange({
      ...filterValue,
      value: values.map((i: any) => i.value).join(',')
    });
  };

  return (
    <Select
      options={options}
      onChange={handleOnChange}
      isMulti={isMulti}
      value={filterValue._source}
      noOptionsMessage={() => t('common.noValue')}
    />
  );
});

export default ProductionUnitTypeSelectFilter;
