import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import api from '../../api/Api';

import styles from './ProductionUnitSelect.module.scss';
import formStyles from '../../form/form.module.scss';
import AppStateContext from '../../context/AppStateContext';
import { Resource } from '../../tools/resourceTools';
import { DispatchResourceAction, SET_RESOURCES } from '../../context/AppStateReducer';
import { ProductionUnitRef } from '@memnon.orka/orka-sdk';
import SiteLabel from '../Production/SiteLabel';

type Props = {
  onChange: (value?: ProductionUnitRef) => void;
  defaultValue?: ProductionUnitRef;
  value?: ProductionUnitRef;
  disabled?: boolean;
  isInvalid?: boolean;
  isClearable?: boolean;
};

type Option = {
  value: string;
  label: any;
  _original: ProductionUnitRef;
};

const ProductionUnitSelect: FC<Props> = ({
  onChange,
  value,
  disabled,
  isInvalid,
  isClearable,
  defaultValue
}) => {
  const { t } = useTranslation('i18n');

  const { dispatch } = useContext(AppStateContext);

  const [selected, setSelected] = useState<Option | Option[]>();

  const composeOption = (productionUnit: ProductionUnitRef): Option => {
    return {
      _original: productionUnit,
      value: productionUnit.id,
      label: (
        <div className={styles.label}>
          <span>{productionUnit.name}</span>
          <span>-</span>
          <SiteLabel className={styles.site} siteId={productionUnit.siteId} />
        </div>
      )
    };
  };

  useEffect(() => {
    setSelected(!value ? undefined : composeOption(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, t]);

  const handleOnChange = (items?: Option) => {
    onChange(items ? items._original : undefined);
  };

  const loadData = async (search?: string) => {
    const request: Record<string, string | number | boolean> = search
      ? {
          size: 200,
          search: `startsWith:${search}`
        }
      : {
          size: 200
        };

    const productionUnits = await api.production.listProductionUnits(request);
    let data = productionUnits.data.items.map(composeOption);
    dispatch({
      payload: productionUnits.data,
      resourceName: Resource.PRODUCTION_UNIT,
      type: SET_RESOURCES
    } as DispatchResourceAction);

    //Force to add defaultValue as selection if not present and no search
    if (!search && defaultValue && !productionUnits.data.items.some((a) => a.id === defaultValue.id)) {
      data = [composeOption(defaultValue), ...data];
    }

    return data;
  };
  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <AsyncSelect
        key={`select-productionUnit`}
        defaultOptions
        loadOptions={loadData}
        className={isInvalid ? formStyles.invalid : formStyles.valid}
        onChange={handleOnChange as any}
        isMulti={false}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
        isClearable={isClearable || false}
      />
    </>
  );
};

export default ProductionUnitSelect;
