import React, { FC, forwardRef, useEffect, useState, useImperativeHandle, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, PermissionSet } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import RoleLinked from './RoleLinked';
import PermissionsInput from '../form/PermissionsInput';

type UpdatePermissionSetProps = FormProps<PermissionSet> & {
  mode: 'update';
  permissionSet: PermissionSet;
  onMemberCountChange?: (count: number) => void;
};

type CreatePermissionSetProps = FormProps<PermissionSet> & {
  mode: 'create';
  //Property not relevent for creation but only to simplify the code
  permissionSet?: undefined;
  onMemberCountChange?: undefined;
};

const PermissionSetForm: FC<CreatePermissionSetProps | UpdatePermissionSetProps> = forwardRef(
  ({ onChange, disabled, permissionSet, onMemberCountChange }, ref) => {
    const { t } = useTranslation('i18n');

    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [grantPermissions, setGrantPermissions] = useState<Permission[]>([]);

    const [errors, setErrors] = useState<Record<string, string>>({});

    useImperativeHandle(ref, () => ({
      isValid() {
        const errors: Record<string, string> = {};
        if (!name) {
          errors.name = t('form.error.required');
        }
        setErrors(errors);
        return !Object.keys(errors).length;
      }
    }));

    useEffect(() => {
      setName(permissionSet?.name);
      setDescription(permissionSet?.description);
      setGrantPermissions(permissionSet?.grantPermissions || []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionSet]);

    useEffect(() => {
      if (name || description || grantPermissions.length) {
        onChange({
          name,
          description,
          grantPermissions
        });
      }
    }, [name, description, grantPermissions, onChange]);

    const disableComp = disabled;

    const handleNameChanged = (event: ChangeEvent<any>) => {
      setName(event.target.value);
      const { name: nameError, ...otherErrors } = errors;
      if (nameError) {
        //Clean the error
        setErrors(otherErrors);
      }
    };

    return (
      <Container>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('permission-set.name')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <Form.Control
              id="name"
              required
              type="text"
              disabled={disableComp}
              value={name}
              isInvalid={!!errors.name}
              onChange={handleNameChanged}
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('permission-set.description')}
          </Col>
          <Col sm={8} className={formStyles.input}>
            <textarea
              className="form-control"
              id="description"
              disabled={disableComp}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('permission-set.permissions')}
          </Col>
          <Col sm={8} className={formStyles.input}>
            <PermissionsInput
              disabled={disableComp}
              onChange={setGrantPermissions}
              value={grantPermissions}
            />
          </Col>
        </Row>
        {permissionSet && (
          <Row>
            <Col sm={4} className={formStyles.field}>
              {t('permission-set.linkedRoles')}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <div className={formStyles.inputTop}>
                <RoleLinked permissionSet={permissionSet} onChange={onMemberCountChange} />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
);

export default PermissionSetForm;
