import { Location } from "react-router-dom";
import { BodyApi, DataGridLoadDataParam } from "../@types/webapp";
import { Buffer } from 'buffer'

export const DEFAULT_TEXT_DELAY = 500;

export const createStateForUrl = (state: Record<string, any>) => {
  return `_state=${Buffer.from(JSON.stringify(state), 'utf-8').toString("base64")}`;
}

export const getStateFromUrl = (location: Location): Record<string, any> | undefined => {
  const state = new URLSearchParams(location.search).get('_state');
  if (!state) {
    return undefined
  }
  const content = Buffer.from(state || "", "base64").toString('utf-8');
  return JSON.parse(content);
}

export const removeStateFromUrl = (location: Location): string => {
  const queryString = new URLSearchParams(location.search);
  queryString.delete("_state");
  const query = queryString.toString()
  return `${location.pathname}${query ? `?${query}` : ""}`;
}


export const createBody = ({ filterValue, limit, skip, sortInfo, columns }: DataGridLoadDataParam) => {
  const body = (filterValue || []).reduce(
    (acc, currentValue) => {
      if (currentValue?.value?.length) {

        const column = columns.find(col => col.name === currentValue.name);

        const filterName = column?.filterProps?.name || currentValue.name
        if (currentValue.operator === "eq" || currentValue.operator === "inlist") {
          return {
            ...acc,
            [filterName]: `equals:${currentValue.value}`,
          };
        }
        return {
          ...acc,
          [filterName]: `${currentValue.operator}:${currentValue.value}`,
        };
      }
      return acc;
    },
    {
      from: skip,
      size: limit,
    } as BodyApi
  );
  if (sortInfo) {

    const sortInfoValue =
      sortInfo.dir === 1 ? "asc" : "desc";
    body.sortBy = `${sortInfoValue}:${sortInfo.name}`;
  }
  return body;
};
