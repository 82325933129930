import React, { FC, useState, useEffect } from 'react';

import Select from 'react-select';

import { Form } from 'react-bootstrap';
import styles from '../form/form.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?: boolean;
  isInvalid?: boolean;
  onChange: (value?: string) => void;
  value?: string;
  options: string[];
  isClearable?: boolean;
};

type Option = {
  value: string;
  label: string;
};

const BasicSelect: FC<Props> = ({ onChange, value, disabled, isInvalid, options, isClearable }) => {
  const { t } = useTranslation('i18n');
  const [optionItems] = useState(
    options.map((option) => ({
      value: option,
      label: option
    }))
  );
  const [selected, setSelected] = useState<Option>();

  useEffect(() => {
    setSelected(!value ? undefined : optionItems.find((option) => option.value === value));
  }, [value, optionItems]);

  const handleOnChange = (items?: Option) => {
    onChange(items ? items.value : undefined);
  };

  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <Select
        className={isInvalid ? styles.invalid : styles.valid}
        options={optionItems}
        onChange={handleOnChange as any}
        isMulti={false}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
        isClearable={isClearable}
      />
    </>
  );
};

export default BasicSelect;
