import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAssetPath, getProductionLinePath, getProductionUnitPath, getSitePath } from './AppRoutes';
import SideMenuSubList from './SideMenuSublist';
import { SideMenuRouteProps } from './SideMenuRoute';
import AbilityContext from '../context/AbilityContext';
import { useAbility } from '@casl/react';
import { Resource } from '../tools/resourceTools';

import ProductionUnit from '@mui/icons-material/DisplaySettings';
import SiteIcon from '@mui/icons-material/Business';
import AssetIcon from '@mui/icons-material/Extension';
import ProductionIcon from '@mui/icons-material/Settings';
import ProductionLineIcon from '../icons/ProductionLineIcon';

const ROUTES = [
  {
    resource: Resource.SITE,
    path: getSitePath({ mode: 'list' }),
    Icon: SiteIcon
  },
  {
    resource: Resource.PRODUCTION_UNIT,
    path: getProductionUnitPath({ mode: 'list' }),
    Icon: ProductionUnit
  },
  {
    resource: Resource.PRODUCTION_LINE,
    path: getProductionLinePath({ mode: 'list' }),
    Icon: ProductionLineIcon
  },
  {
    resource: Resource.ASSET,
    path: getAssetPath({ mode: 'list' }),
    Icon: AssetIcon
  }
];

const ConfigurationList = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const [routes, setRoutes] = useState<SideMenuRouteProps[]>([]);

  useEffect(() => {
    const routes = ROUTES.filter((route) => ability.can('get', route.resource)).map((route) => {
      return {
        ...route,
        name: t(`${route.resource}.title`)
      };
    });
    setRoutes(routes);
  }, [ability, t]);

  return routes.length ? (
    <SideMenuSubList title={t('sidemenu.configuration')} paths={routes} icon={<ProductionIcon />} />
  ) : (
    <></>
  );
};

export default ConfigurationList;
