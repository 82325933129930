import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';

type Props = {
  title?: string | null;
  toolbar?: any;
};

const AssetFormCard: FC<Props> = ({ title, toolbar, ...props }) => {
  return (
    <Card>
      {(title || toolbar) && (
        <Card.Header className={formStyles.header}>
          <div className={formStyles.cardTitle}>{title || ''}</div>
          {toolbar && <div className={formStyles.cardToolbar}>{toolbar}</div>}
        </Card.Header>
      )}
      <Card.Body {...props}></Card.Body>
    </Card>
  );
};

export default AssetFormCard;
