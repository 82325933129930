import React, { FC, forwardRef } from 'react';
import { FilterValue } from '../../@types/webapp';
import CountrySelect from '../Country/CountrySelect';

type Props = {
  onChange: (value: FilterValue) => {};
  filterValue: FilterValue;
  isMulti?: boolean;
};

const CountryFilter: FC<Props> = forwardRef(({ onChange, filterValue, isMulti }, ref) => {
  const handleOnChange = (items: any) => {
    const values = items ? (Array.isArray(items) ? items : [items]) : [];
    onChange({
      ...filterValue,
      value: values.join(',').toLocaleLowerCase(),
      _source: items
    });
  };

  return (
    <div>
      <CountrySelect isMulti={isMulti || false} onChange={handleOnChange} value={filterValue._source} />
    </div>
  );
});

export default CountryFilter;
