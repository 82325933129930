import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';

import { Form } from 'react-bootstrap';
import styles from '../form/form.module.scss';
import { extractCountryFromSiteId, generateSiteId } from '../tools/siteTools';

const incrementValues = [1, 2, 3, 4, 5, 6, 7, 8, 9];

type Props = {
  onChange: (value?: string) => void;
  value?: string;
  countryCode?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  className?: string;
};

type Option = {
  value: string;
  label: string;
};

const SiteIdSelect: FC<Props> = ({ onChange, value, disabled, isInvalid, className, countryCode }) => {
  const { t } = useTranslation('i18n');

  const [options] = useState<Option[]>(incrementValues.map((v) => ({ value: `${v}`, label: `${v}` })));

  const [increment, setIncrement] = useState<string>();
  const [country, setCountry] = useState<string>();

  const handleOnChange = (items?: Option) => {
    const { country } = extractCountryFromSiteId(value || '-');
    let id: string | undefined = undefined;
    if (items !== undefined) {
      id = generateSiteId(countryCode || country || ' ', items?.value);
    }
    onChange(id);
  };

  useEffect(() => {
    const { country, increment } = extractCountryFromSiteId(value || '-');
    setIncrement(increment);
    setCountry(country);
  }, [value]);

  useEffect(() => {
    let id: string | undefined = undefined;
    if (increment !== undefined) {
      id = generateSiteId(countryCode || country, increment);
    }
    onChange(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  return (
    <div className={`${styles.inline} ${className}`}>
      <div className={styles.small}>
        <Form.Control type="text" value={countryCode || country} disabled className={styles.small} />
      </div>
      <span className={styles.verticalAlign}>-</span>
      <Select
        className={`${isInvalid ? styles.invalid : styles.valid} ${styles.selectSmall}`}
        options={options}
        onChange={handleOnChange as any}
        isMulti={false}
        value={{ value: increment, label: `${increment}` }}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
      />
    </div>
  );
};

export default SiteIdSelect;
