import { Permission } from "@memnon.orka/orka-sdk";


export const getPermissionShortLabel = (permission: Permission) => {
  const permissions = [
    permission.actions.some(a => a === 'post' || a === 'manage') ? "C" : "-",
    permission.actions.some(a => a === 'get' || a === 'manage') ? "R" : "-",
    permission.actions.some(a => a === 'put' || a === 'manage') ? "U" : "-",
    permission.actions.some(a => a === 'delete' || a === 'manage') ? "D" : "-",
  ];

  return permissions.join("")
};

export const extractCountryFromSiteId = (siteId: string) => {
  const [country, increment] = siteId.split('-');
  return { country, increment };
};
