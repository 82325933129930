import React, { FC, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, AssetPost, AssetPut } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import AssetFormCard from './AssetFormCard';
import AssetAssociatedLabel from '../components/Production/AssetAssociatedLabel';
import { getAssetPath, getProductionLinePath, getProductionUnitPath } from '../navigation/AppRoutes';
import OrkaLink from '../components/Link/OrkaLink';
import { getAssetTypeLabel } from '../tools/assetTools';

type Props = FormProps<AssetPut | AssetPost> & {
  mode: 'update' | 'create';
  asset: Partial<Asset>;
};

const AssetStatusForm: FC<Props> = forwardRef(({ onChange, disabled, asset, mode }, ref) => {
  const { t } = useTranslation('i18n');

  useImperativeHandle(ref, () => ({
    isValid() {
      return true;
    }
  }));

  const disableComp = disabled;

  const handleCommentsChanged = (comments?: string) => {
    onChange({ ...asset, comments });
  };

  return (
    <AssetFormCard title={t('asset.sections.status')}>
      <Container>
        <Row key={`status`}>
          <Col sm={4} className={formStyles.field}>
            {t(`asset.status`)}
          </Col>
          <Col sm={8} className={formStyles.input}>
            <AssetAssociatedLabel className={formStyles.inputTitle} associated={!!asset.isAssigned} />
          </Col>
        </Row>
        <Row key={`comments`}>
          <Col sm={4} className={formStyles.field}>
            {t(`asset.comments`)}
          </Col>
          <Col sm={8} className={formStyles.input}>
            <textarea
              className="form-control"
              id="comments"
              disabled={disableComp}
              value={asset.comments}
              onChange={(event) => handleCommentsChanged(event.target.value)}
            />
          </Col>
        </Row>
        {asset.assignedToProductionLine && (
          <Row key={`assignedToProductionLine`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.assignedToProductionLine`)}
            </Col>
            <Col sm={8} className={formStyles.inputLink}>
              <OrkaLink
                url={getProductionLinePath({
                  mode: 'get',
                  productionLineId: asset.assignedToProductionLine.id
                })}
                openNewPage
                label={asset.assignedToProductionLine.name}
              />
            </Col>
          </Row>
        )}
        {asset.assignedToProductionUnit && (
          <Row key={`assignedToProductionUnit`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.assignedToProductionUnit`)}
            </Col>
            <Col sm={8} className={formStyles.inputLink}>
              <OrkaLink
                url={getProductionUnitPath({
                  mode: 'get',
                  productionUnitId: asset.assignedToProductionUnit.id
                })}
                openNewPage
                label={asset.assignedToProductionUnit.name}
              />
            </Col>
          </Row>
        )}
        {asset.assignedToAsset && (
          <Row key={`assignedToAsset`}>
            <Col sm={4} className={formStyles.field}>
              {getAssetTypeLabel(asset.assignedToAsset.type)}
            </Col>
            <Col sm={8} className={formStyles.inputLink}>
              <OrkaLink
                url={getAssetPath({
                  mode: 'update',
                  assetId: asset.assignedToAsset.id
                })}
                openNewPage
                label={asset.assignedToAsset.name}
              />
            </Col>
          </Row>
        )}
      </Container>
    </AssetFormCard>
  );
});

export default AssetStatusForm;
