import React, { useContext, useEffect, useState, FC } from 'react';
import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import AppStateContext from '../../context/AppStateContext';
import { RoleRef } from '@memnon.orka/orka-sdk';
import styles from './SwitchRole.module.scss';
import api from '../../api/Api';
import { SET_ACCESS } from '../../context/AppStateReducer';
import { getErrorKey } from '../../tools/errorTools';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type DataGridProps = {
  className?: string;
};

const SwitchRole: FC<DataGridProps> = ({ className }) => {
  const [roles, setRoles] = useState<RoleRef[]>([]);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('i18n');

  const {
    state: { access },
    dispatch
  } = useContext(AppStateContext);

  useEffect(() => {
    setRoles(access?.roles || []);

    setName(access?.roles.find((r) => r.id === access.roleId)?.name || '');
  }, [access]);

  const handleSwitchRole = (role: RoleRef) => {
    const switchRole = async () => {
      setLoading(true);
      api.setRoleId(role.id);
      setName(role.name);
      try {
        const access = await api.myAccess.getMyAccess();
        dispatch({ type: SET_ACCESS, payload: access.data });
      } catch (error) {
        toast.error(t(getErrorKey(error)), {
          autoClose: false
        });
      }
      setLoading(false);
    };

    switchRole();
  };

  return !access || roles.length <= 1 ? (
    <></>
  ) : (
    <DropdownButton
      id="dropdown-basic-button"
      variant="outline-secondary"
      title={
        <>
          {loading && <Spinner className={styles.loading}></Spinner>}
          <span className={styles.label}>{name}</span>
        </>
      }
      className={className}
      disabled={loading}
    >
      {roles.map((role) => (
        <Dropdown.Item
          key={`role_${role.id}`}
          disabled={role.id === access.roleId}
          onClick={() => handleSwitchRole(role)}
        >
          {role.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default SwitchRole;
