import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MemberLinked.module.scss';
import LoadingComponent from '../components/Loading/LoadingComponent';
import api from '../api/Api';
import { getErrorKey } from '../tools/errorTools';

import { getMemberPath } from '../navigation/AppRoutes';
import { createStateForUrl } from '../datagrid/datagridTools';
import { Role } from '@memnon.orka/orka-sdk';
import { UtilRequiredKeys } from '../@types/webapp';
import OrkaLink from '../components/Link/OrkaLink';

type Props = {
  onChange?: (count: number) => void;
  role: UtilRequiredKeys<Role, 'id' | 'name'>;
};

const MemberLinked: FC<Props> = ({ onChange, role }) => {
  const { t } = useTranslation('i18n');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  const [url, setUrl] = useState<string>();

  useEffect(() => {
    setUrl(
      `${getMemberPath({ mode: 'list' })}?${createStateForUrl({ role: { id: role.id, name: role.name } })}`
    );
  }, [role]);

  const handleLoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.members.listMembers({
        from: 0,
        size: 0,
        'roles.id': role.id
      });
      setMessage(t('role.members', { count: data.total }) || '');
      setError(false);
      if (onChange) {
        onChange(data.total);
      }
    } catch (error: any) {
      setError(true);
      setMessage(t(getErrorKey(error)) || '');
    }

    setLoading(false);
  };

  useEffect(() => {
    handleLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <LoadingComponent size="sm" />
  ) : error ? (
    <div className={styles.error}>{message}</div>
  ) : (
    <div className={styles.info}>
      {message} {url && <OrkaLink url={url} openNewPage label="" />}
    </div>
  );
};

export default MemberLinked;
