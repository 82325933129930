import React, { FC, useState, useEffect } from 'react';

import Select from 'react-select';

import { Form } from 'react-bootstrap';
import styles from '../form/form.module.scss';
import { useTranslation } from 'react-i18next';
import { getAssetTypeLabel } from '../tools/assetTools';

export const ASSET_TYPES = [
  'adConverter',
  'audioPlayer',
  'cartridge',
  'cleaner',
  'discPlayer',
  'eQStuder',
  'eqDisc',
  'head',
  'multiViewer',
  'preAmp',
  'processor',
  'remote',
  'rfAnalyser',
  'robot',
  'samplingRateConverter',
  'stylus',
  'synchroniser',
  'telcom',
  'timeBaseCorrector',
  'videoEncoder',
  'videoPlayer',
  'workStation'
];

type Props = {
  disabled?: boolean;
  isInvalid?: boolean;
  onChange: (value?: string) => void;
  value?: string;
};

type Option = {
  value: string;
  label: string;
};

const AssetTypeSelect: FC<Props> = ({ onChange, value, disabled, isInvalid }) => {
  const { t } = useTranslation('i18n');
  const [options] = useState(
    ASSET_TYPES.map((type) => ({
      value: type,
      label: getAssetTypeLabel(type)
    }))
  );
  const [selected, setSelected] = useState<Option | Option[]>();

  useEffect(() => {
    setSelected(!value ? undefined : options.find((country) => country.value === value));
  }, [value, options]);

  const handleOnChange = (items?: Option) => {
    onChange(items ? items.value : undefined);
  };

  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <Select
        className={isInvalid ? styles.invalid : styles.valid}
        options={options}
        onChange={handleOnChange as any}
        isMulti={false}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
      />
    </>
  );
};

export default AssetTypeSelect;
