import React, { useReducer, useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/react-toastify.scss';
import './styles/reactdatagrid-default-orka.css';
import './styles/amplify.css';
import Header from './header/Header';
import Footer from './footer/Footer';
import appStateReducer, { SET_ACCESS, initialState } from './context/AppStateReducer';
import AppStateContextProvider from './context/AppStateContextProvider';
import '@inovua/reactdatagrid-community/index.css';
import * as Sentry from '@sentry/react';
import AppRoutes from './navigation/AppRoutes';
import Box from '@mui/material/Box';
import SideMenu from './navigation/SideMenu';
import { BrowserRouter } from 'react-router-dom';
import LoadingPage from './components/Loading/LoadingPage';
import api from './api/Api';
import { AbilityProvider } from './context/AbilityContext';
import { PureAbility } from '@casl/ability';
import { ToastContainer, Slide, toast } from 'react-toastify';
import ConvertExternalState from './ConvertExternalState';
import { getErrorKey } from './tools/errorTools';
import { useTranslation } from 'react-i18next';

Sentry.init({
  dsn: 'https://de86750335374a939b53da06b691afea@o4504871339622400.ingest.sentry.io/4504995319054341',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend: (event: any) => {
    if (process.env.REACT_APP_ENV === 'local') {
      return null;
    }
    return event;
  }
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'orka',
        endpoint: process.env.REACT_APP_API,
        region: process.env.REACT_APP_AUTH_REGION
      }
    ]
  }
});

function App() {
  const [appState, dispatch] = useReducer(appStateReducer, initialState);
  const [loading, setLoading] = useState(false);
  const [ability, setAbility] = useState<PureAbility>(new PureAbility());
  const { t } = useTranslation('i18n');

  const loadAccess = async () => {
    setLoading(true);
    try {
      const access = await api.getMyAccess();
      dispatch({ type: SET_ACCESS, payload: access.data });
      api.setRoleId(access.data.roleId);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ability = appState.state.access
      ? new PureAbility((appState.state.access.rules as any) || [])
      : new PureAbility();

    setAbility(ability);
  }, [appState.state.access]);

  return (
    <AppStateContextProvider value={{ ...appState, dispatch }}>
      <BrowserRouter>
        <ToastContainer autoClose={10000} hideProgressBar newestOnTop transition={Slide} draggable={false} />
        <Header />
        {loading ? (
          <LoadingPage />
        ) : (
          <ConvertExternalState>
            <AbilityProvider value={ability}>
              <Box sx={{ display: 'flex' }}>
                <SideMenu></SideMenu>
                <Box className="App" sx={{ width: { sm: `calc(100% - ${240}px)` } }}>
                  <div className="App-content">
                    <AppRoutes></AppRoutes>
                  </div>
                </Box>
              </Box>
              <Footer />
            </AbilityProvider>
          </ConvertExternalState>
        )}
      </BrowserRouter>
    </AppStateContextProvider>
  );
}
export default withAuthenticator(App, {
  className: 'authentication',
  hideSignUp: true
});
