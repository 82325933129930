import React, { FC } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { useTranslation } from 'react-i18next';
import { getProductionUnitPath } from '../../navigation/AppRoutes';
import SiteLabel from './SiteLabel';
import styles from './ProductionUnitBreadcrumb.module.scss';
import { Link } from 'react-router-dom';

type Props = { productionUnit: { name: string; siteId: string }; className?: string };

const ProductionUnitBreadcrumb: FC<Props> = ({ productionUnit, className }) => {
  const { t } = useTranslation('i18n');

  return (
    <div className={className}>
      <Breadcrumb>
        <Breadcrumb.Item active>{t('sidemenu.configuration')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: getProductionUnitPath({ mode: 'list' }) }}>
          {t('production-unit.title')}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{
            to: getProductionUnitPath({ mode: 'list' }),
            state: { siteId: productionUnit.siteId }
          }}
        >
          <SiteLabel siteId={productionUnit.siteId} className={styles.siteLabel} />
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{productionUnit.name}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default ProductionUnitBreadcrumb;
