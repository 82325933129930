import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MemberList from '../member/MemberList';
import RoleList from '../role/RoleList';
import RoleDialog from '../role/RoleDialog';
import SiteDialog from '../site/SiteDialog';
import SiteList from '../site/SiteList';
import ProductionUnitList from '../productionUnit/ProductionUnitList';
import ProductionUnitDialog from '../productionUnit/ProductionUnitDialog';
import ProductionUnitPage from '../productionUnit/ProductionUnitPage';
import AssetDialog from '../asset/AssetDialog';
import AssetList from '../asset/AssetList';
import ProductionUnitAssetAssociationDialog from '../components/Production/ProductionUnitAssetAssociationDialog';
import ProductionLineDialog from '../productionLine/ProductionLineDialog';
import ProductionLineList from '../productionLine/ProductionLineList';
import MemberDialog from '../member/MemberDialog';
import PermissionSetList from '../permissionSet/PermissionSetList';
import PermissionSetDialog from '../permissionSet/PermissionSetDialog';
import NodeRedPage from '../nodeRed/NodeRedPage';
export const defaultPath = '/';
export const permissionsPath = '/permissions';

export const getRolePath = (
  options: ({ mode: 'list' } | { mode: 'create' } | { mode: 'update'; roleId: string }) & {
    relative?: boolean;
  }
) => {
  const route = '/roles';
  const prefix = options.relative ? '' : `${route}/`;

  if (options?.mode === 'create') {
    return `${prefix}${options?.mode}`;
  }
  if (options?.mode === 'update') {
    return `${prefix}${options?.mode}/${options.roleId}`;
  }
  return route;
};
export const getSitePath = (
  options: ({ mode: 'list' } | { mode: 'create' } | { mode: 'update'; siteId: string }) & {
    relative?: boolean;
  }
) => {
  const route = '/sites';
  const prefix = options.relative ? '' : `${route}/`;

  if (options?.mode === 'create') {
    return `${prefix}${options?.mode}`;
  }
  if (options?.mode === 'update') {
    return `${prefix}${options?.mode}/${options.siteId}`;
  }
  return route;
};
export const getProductionUnitPath = (
  options: (
    | { mode: 'list' }
    | { mode: 'create' }
    | { mode: 'update'; productionUnitId: string; module: 'details' | ':module'; siteId?: undefined }
    | { mode: 'update'; productionUnitId: string; module: 'workStation' | 'robot'; siteId: string }
    | { mode: 'get'; productionUnitId: string }
  ) & {
    relative?: boolean;
  }
) => {
  const route = '/production-units';
  const prefix = options.relative ? '' : `${route}/`;

  if (options?.mode === 'create') {
    return `${prefix}${options?.mode}`;
  }
  if (options?.mode === 'update') {
    let path = '';
    const module = options.module === 'details' ? '' : `/${options.module}`;
    if (options.relative) {
      path = `${prefix}${options?.mode}${module}`;
    } else {
      path = `${prefix}${options.productionUnitId}/${options?.mode}${module}`;
    }
    if (options.siteId) {
      path = `${path}?siteId=${options.siteId}`;
    }
    return path;
  }
  if (options?.mode === 'get') {
    return `${prefix}${options.productionUnitId}`;
  }
  return route;
};
export const getProductionLinePath = (
  options: (
    | { mode: 'list' }
    | { mode: 'get'; productionLineId: string }
    | { mode: 'create'; productionUnitId?: string }
    | { mode: 'update'; productionLineId: string; productionUnitId?: string }
  ) & {
    relative?: boolean;
  }
) => {
  const productionUnitId = (options as any).productionUnitId;

  const route = 'production-lines';
  let prefix = options.relative ? '' : `/${route}/`;

  if (productionUnitId && options.relative) {
    prefix = `${route}/`;
  } else if (productionUnitId) {
    prefix = `${getProductionUnitPath({ mode: 'get', productionUnitId })}/${route}/`;
  }

  if (options?.mode === 'create') {
    return `${prefix}${options?.mode}`;
  }
  if (options?.mode === 'update') {
    return `${prefix}${options?.mode}/${options.productionLineId}`;
  }
  if (options?.mode === 'get') {
    return `${prefix}${options.productionLineId}`;
  }
  return `/${route}`;
};

export const getAssetPath = (
  options: ({ mode: 'list' } | { mode: 'create' } | { mode: 'update'; assetId: string }) & {
    relative?: boolean;
  }
) => {
  const route = '/assets';
  const prefix = options.relative ? '' : `${route}/`;

  if (options?.mode === 'create') {
    return `${prefix}${options?.mode}`;
  }
  if (options?.mode === 'update') {
    return `${prefix}${options?.mode}/${options.assetId}`;
  }

  return route;
};

export const getPermissionSetPath = (
  options: ({ mode: 'list' } | { mode: 'create' } | { mode: 'update'; permissionSetId: string }) & {
    relative?: boolean;
  }
) => {
  const route = '/permission-sets';
  const prefix = options.relative ? '' : `${route}/`;

  if (options?.mode === 'create') {
    return `${prefix}${options?.mode}`;
  }
  if (options?.mode === 'update') {
    return `${prefix}${options?.mode}/${options.permissionSetId}`;
  }

  return route;
};

export const getNodeRedPath = () => {
  return '/app-node-red';
};

export const getMemberPath = (
  options: ({ mode: 'list' } | { mode: 'create' } | { mode: 'update'; memberId: string }) & {
    relative?: boolean;
  }
) => {
  const route = '/members';
  const prefix = options.relative ? '' : `${route}/`;

  if (options?.mode === 'create') {
    return `${prefix}${options?.mode}`;
  }
  if (options?.mode === 'update') {
    return `${prefix}${options?.mode}/${options.memberId}`;
  }
  return route;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={defaultPath} element={<MemberList />}></Route>
      <Route path={getMemberPath({ mode: 'list' })} element={<MemberList />}>
        <Route path={getMemberPath({ mode: 'create', relative: true })} element={<MemberDialog />}></Route>
        <Route
          path={getMemberPath({ mode: 'update', relative: true, memberId: ':memberId' })}
          element={<MemberDialog />}
        ></Route>
      </Route>
      <Route path={getRolePath({ mode: 'list' })} element={<RoleList />}>
        <Route path={getRolePath({ mode: 'create', relative: true })} element={<RoleDialog />}></Route>
        <Route
          path={getRolePath({ mode: 'update', relative: true, roleId: ':roleId' })}
          element={<RoleDialog />}
        ></Route>
      </Route>
      <Route path={getNodeRedPath()} element={<NodeRedPage />} />
      <Route path={getPermissionSetPath({ mode: 'list' })} element={<PermissionSetList />}>
        <Route
          path={getPermissionSetPath({ mode: 'create', relative: true })}
          element={<PermissionSetDialog />}
        ></Route>
        <Route
          path={getPermissionSetPath({ mode: 'update', relative: true, permissionSetId: ':permissionSetId' })}
          element={<PermissionSetDialog />}
        ></Route>
      </Route>
      <Route path={getSitePath({ mode: 'list' })} element={<SiteList />}>
        <Route path={getSitePath({ mode: 'create', relative: true })} element={<SiteDialog />}></Route>
        <Route
          path={getSitePath({ mode: 'update', relative: true, siteId: ':siteId' })}
          element={<SiteDialog />}
        ></Route>
      </Route>
      <Route
        path={getProductionUnitPath({ mode: 'get', relative: false, productionUnitId: ':productionUnitId' })}
        element={<ProductionUnitPage />}
      >
        <Route
          path={getProductionLinePath({
            mode: 'create',
            productionUnitId: ':productionUnitId',
            relative: true
          })}
          element={<ProductionLineDialog />}
        ></Route>
        <Route
          path={getProductionLinePath({
            mode: 'update',
            relative: true,
            productionUnitId: ':productionUnitId',
            productionLineId: ':productionLineId'
          })}
          element={<ProductionLineDialog />}
        ></Route>

        <Route
          path={getProductionUnitPath({
            mode: 'update',
            relative: true,
            productionUnitId: ':productionUnitId',
            module: 'details'
          })}
          element={<ProductionUnitDialog />}
        ></Route>
        <Route
          path={getProductionUnitPath({
            mode: 'update',
            relative: true,
            productionUnitId: ':productionUnitId',
            module: ':module'
          })}
          element={<ProductionUnitAssetAssociationDialog />}
        ></Route>
      </Route>
      <Route path={getProductionUnitPath({ mode: 'list' })} element={<ProductionUnitList />}>
        <Route
          path={getProductionUnitPath({ mode: 'create', relative: true })}
          element={<ProductionUnitDialog />}
        ></Route>
      </Route>

      <Route path={getAssetPath({ mode: 'list' })} element={<AssetList />}>
        <Route path={getAssetPath({ mode: 'create', relative: true })} element={<AssetDialog />}></Route>
        <Route
          path={getAssetPath({
            mode: 'update',
            relative: true,
            assetId: ':assetId'
          })}
          element={<AssetDialog />}
        ></Route>
      </Route>

      <Route path={getProductionLinePath({ mode: 'list' })} element={<ProductionLineList />}>
        <Route
          path={getProductionLinePath({
            mode: 'create',
            relative: true
          })}
          element={<ProductionLineDialog />}
        ></Route>
        <Route
          path={getProductionLinePath({
            mode: 'update',
            relative: true,
            productionLineId: ':productionLineId'
          })}
          element={<ProductionLineDialog />}
        ></Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
