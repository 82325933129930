import React, { FC, forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, AssetPost, AssetPut } from '@memnon.orka/orka-sdk';
import { AssetConfig, FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import AssetFormCard from './AssetFormCard';
import BasicSelect from './BasicSelect';
import { ASSET_CONFIG } from '../config/assetConfig';
import BasicMultiSelect from './BasicMultiSelect';
import AssetSelect from '../components/Select/AssetSelect';
import CreatableBasicNumberSelect from './CreatableBasicNumberSelect';
import { uniq } from 'lodash';
import OrkaLink from '../components/Link/OrkaLink';
import { getAssetPath } from '../navigation/AppRoutes';

type Props = FormProps<AssetPut | AssetPost> & {
  mode: 'update' | 'create';
  asset: Partial<Asset> & {
    remoteControlProtocol?: string;
    applicationProtocols?: string[];
    isrPort?: boolean;
    firewireDeviceId?: string;
    ethernetMacAddress?: string;
    head?: Asset;
    ports?: number[];
    ingestChannels?: number;
  };
};

const AssetPropertiesForm: FC<Props> = forwardRef(({ onChange, disabled, asset, mode }, ref) => {
  const { t } = useTranslation('i18n');

  const [config, setConfig] = useState<AssetConfig>();

  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setConfig(ASSET_CONFIG.find((c) => c.type === asset.type));
  }, [asset]);

  useImperativeHandle(ref, () => ({
    isValid() {
      const errors: Record<string, string> = {};
      if (config?.categories?.length && !asset.category) {
        errors.category = t('form.error.required');
      }
      setErrors(errors);
      return !Object.keys(errors).length;
    }
  }));

  const disableComp = disabled;

  const cleanError = (field: string) => {
    const { [field]: fieldError, ...otherErrors } = errors;
    if (fieldError) {
      //Clean the error
      setErrors(otherErrors);
    }
  };

  const handleCategoryChanged = (category?: string) => {
    onChange({ ...asset, category });
    cleanError('category');
  };
  const handleRemoteControlProtocolChanged = (remoteControlProtocol?: string) => {
    onChange({ ...asset, remoteControlProtocol } as any);
    cleanError('remoteControlProtocol');
  };
  const handleApplicationProtocolsChanged = (applicationProtocols?: string[]) => {
    onChange({ ...asset, applicationProtocols } as any);
    cleanError('applicationProtocols');
  };
  const handleHeadChanged = (head?: Asset) => {
    onChange({ ...asset, head } as any);
    cleanError('head');
  };
  const handlePortsChanged = (ports?: number[]) => {
    onChange({ ...asset, ports } as any);
    cleanError('ports');
  };
  const handleIsrPortChanged = (isrPort: boolean) => {
    onChange({ ...asset, isrPort } as any);
    cleanError('isrPort');
  };
  const handleFirewireDeviceIdChanged = (firewireDeviceId?: string) => {
    onChange({ ...asset, firewireDeviceId } as any);
    cleanError('firewireDeviceId');
  };
  const handleEthernetMacAddressChanged = (ethernetMacAddress?: string) => {
    onChange({ ...asset, ethernetMacAddress } as any);
    cleanError('ethernetMacAddress');
  };
  const handleIngestChannelsChanged = (ingestChannels?: string) => {
    onChange({ ...asset, ingestChannels } as any);
    cleanError('ingestChannels');
  };
  return !config ? (
    <></>
  ) : (
    <AssetFormCard title={t('asset.sections.properties')}>
      <Container>
        {!!config?.categories?.length && (
          <Row key={`category`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.category`)}
              <span className={formStyles.required}>*</span>
            </Col>
            <Col sm={8} className={formStyles.input}>
              <BasicSelect
                isClearable={false}
                options={config.categories}
                value={asset.category}
                onChange={handleCategoryChanged}
                disabled={disableComp}
                isInvalid={!!errors.category}
              />

              <Form.Control.Feedback type="invalid">{errors.category}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.remoteControlProtocols?.length && (
          <Row key={`remoteControlProtocol`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.remoteControlProtocol`)}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <BasicSelect
                isClearable
                options={config.remoteControlProtocols}
                value={asset.remoteControlProtocol}
                onChange={handleRemoteControlProtocolChanged}
                disabled={disableComp}
                isInvalid={!!errors.remoteControlProtocol}
              />

              <Form.Control.Feedback type="invalid">{errors.remoteControlProtocol}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.modules.some((m) => m === 'isrPort') && (
          <Row key={`isrPort`}>
            <Col sm={4} className={formStyles.field}></Col>
            <Col sm={8} className={formStyles.input}>
              <Form.Check
                className={formStyles.checkbox}
                id="isrPort"
                label={t(`asset.isrPort`)}
                checked={asset.isrPort}
                onChange={() => handleIsrPortChanged(!asset.isrPort)}
              />

              <Form.Control.Feedback type="invalid">{errors.isrPort}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.modules.some((m) => m === 'firewireDeviceId') && (
          <Row key={`firewireDeviceId`}>
            <Col sm={4} className={formStyles.field}>
              {' '}
              {t(`asset.firewireDeviceId`)}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <Form.Control
                id="firewireDeviceId"
                required
                type="text"
                disabled={disableComp}
                value={asset.firewireDeviceId}
                isInvalid={!!errors.firewireDeviceId}
                onChange={(event) => handleFirewireDeviceIdChanged(event.target.value)}
              />

              <Form.Control.Feedback type="invalid">{errors.firewireDeviceId}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.modules.some((m) => m === 'ethernetMacAddress') && (
          <Row key={`ethernetMacAddress`}>
            <Col sm={4} className={formStyles.field}>
              {' '}
              {t(`asset.ethernetMacAddress`)}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <Form.Control
                id="ethernetMacAddress"
                required
                type="text"
                disabled={disableComp}
                value={asset.ethernetMacAddress}
                isInvalid={!!errors.ethernetMacAddress}
                onChange={(event) => handleEthernetMacAddressChanged(event.target.value)}
              />

              <Form.Control.Feedback type="invalid">{errors.ethernetMacAddress}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.applicationProtocols?.length && (
          <Row key={`applicationProtocols`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.applicationProtocols`)}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <BasicMultiSelect
                isClearable
                options={config.applicationProtocols}
                values={asset.applicationProtocols}
                onChange={handleApplicationProtocolsChanged}
                disabled={disableComp}
                isInvalid={!!errors.applicationProtocols}
              />

              <Form.Control.Feedback type="invalid">{errors.applicationProtocols}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.modules.some((m) => m === 'head') && (
          <Row key={`head`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.head`)}
            </Col>
            <Col sm={8} className={formStyles.inputTitle}>
              <div className={formStyles.stretch}>
                <AssetSelect
                  isClearable
                  acceptedTypes={['head']}
                  value={asset.head}
                  onChange={handleHeadChanged}
                  disabled={disableComp}
                  showAssociated
                  isInvalid={!!errors.applicationProtocols}
                  parentId={asset.id}
                  parentType="Asset"
                />
              </div>
              {asset.head && (
                <OrkaLink
                  size="lg"
                  url={getAssetPath({ mode: 'update', assetId: asset.head.id })}
                  openNewPage
                />
              )}

              <Form.Control.Feedback type="invalid">{errors.applicationProtocols}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.modules.some((m) => m === 'ports') && (
          <Row key={`ports`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.ports`)}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <CreatableBasicNumberSelect
                isClearable
                options={uniq([...(asset.ports || []), 23, 9001, 9002, 9003, 9004, 9005])}
                values={asset.ports}
                onChange={handlePortsChanged}
                disabled={disableComp}
                isInvalid={!!errors.applicationProtocols}
              />

              <Form.Control.Feedback type="invalid">{errors.applicationProtocols}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
        {!!config?.modules.some((m) => m === 'ingestChannels') && (
          <Row key={`ingestChannels`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.ingestChannels`)}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <Form.Control
                id="ingestChannels"
                required
                type="number"
                disabled={disableComp}
                value={asset.ingestChannels}
                isInvalid={!!errors.ingestChannels}
                onChange={(event) => handleIngestChannelsChanged(event.target.value)}
              />

              <Form.Control.Feedback type="invalid">{errors.ingestChannels}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
      </Container>
    </AssetFormCard>
  );
});

export default AssetPropertiesForm;
