import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../dialog/DefaultCrudDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductionLinePath, getProductionUnitPath } from '../navigation/AppRoutes';
import { ProductionLine, ProductionLinePost, ProductionLinePut } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import AppStateContext from '../context/AppStateContext';
import { ADD_RESOURCE, DispatchResourceAction, UPDATE_RESOURCE } from '../context/AppStateReducer';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';
import ProductionLineForm from './ProductionLineForm';
import { pick } from 'lodash';

enum Parent {
  PRODUCTION_LINE_LIST,
  PRODUCTION_UNIT_PAGE
}

const ProductionLineDialog = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();
  const params = useParams();

  const ref = useRef();

  const productionUnitId = params.productionUnitId || undefined;
  const productionLineId = params.productionLineId || undefined;

  const parentCaller: Parent = productionUnitId ? Parent.PRODUCTION_UNIT_PAGE : Parent.PRODUCTION_LINE_LIST;

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [existingProductionLine, setExistingProductionLine] = useState<ProductionLine>();
  const [updatedProductionLine, setUpdatedProductionLine] = useState<Partial<ProductionLine>>({});
  const [loading, setLoading] = useState(false);

  const handleLoadProductionLine = async (productionLineId: string) => {
    setLoading(true);
    try {
      const { data } = await api.production.getProductionLine(productionLineId);
      setExistingProductionLine(data);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (productionLineId) {
      handleLoadProductionLine(productionLineId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionLineId]);

  const handleClose = () => {
    setShowModal(false);
    navigate(
      parentCaller === Parent.PRODUCTION_UNIT_PAGE
        ? getProductionUnitPath({ mode: 'get', productionUnitId: productionUnitId! })
        : getProductionLinePath({ mode: 'list' })
    );
  };

  const handleCreate = async (productionLine: ProductionLinePost) => {
    try {
      const { data: productionLineCreated } = await api.production.createProductionLine(
        productionLine as ProductionLinePost
      );
      dispatch({
        payload: productionLineCreated,
        resourceName:
          parentCaller === Parent.PRODUCTION_UNIT_PAGE
            ? Resource.PRODUCTION_UNIT_LINES
            : Resource.PRODUCTION_LINE,
        type: ADD_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`production-line.success.create`, { name: productionLineCreated.name || '' }));
      return productionLineCreated;
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });

      return undefined;
    }
  };

  const handleUpdate = async (productionLineId: string, productionLine: ProductionLinePut) => {
    try {
      const body = pick(productionLine) as ProductionLinePut;
      const { data: productionLineUpdated } = await api.production.updateProductionLine(
        productionLineId,
        body
      );
      dispatch({
        payload: productionLineUpdated,
        resourceName: Resource.PRODUCTION_LINE,
        type: UPDATE_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`production-line.success.update`, { name: productionLineUpdated.name || '' }));
      return productionLineUpdated;
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return undefined;
    }
  };

  const handleSave = async () => {
    if (!(ref?.current as any)?.isValid()) {
      return false;
    }

    setSaving(true);
    let response: ProductionLine | undefined;
    if (productionLineId) {
      response = await handleUpdate(productionLineId, updatedProductionLine as ProductionLinePut);
    } else {
      response = await handleCreate(updatedProductionLine as ProductionLinePost);
    }
    setSaving(false);

    return !!response;
  };
  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: 'post',
        resource: Resource.SITE
      }}
      title={productionLineId ? t(`production-line.update`) : t(`production-line.create`)}
      closeLabel={t('action.cancel')}
      saveLabel={productionLineId ? t(`action.update`) : t(`action.create`)}
      onClose={handleClose}
      saving={saving}
      onSave={handleSave}
      loading={loading}
    >
      {existingProductionLine ? (
        <ProductionLineForm
          ref={ref}
          mode="update"
          productionLine={existingProductionLine}
          onChange={setUpdatedProductionLine}
          disabled={saving}
        />
      ) : (
        <>
          <ProductionLineForm
            ref={ref}
            mode="create"
            onChange={setUpdatedProductionLine}
            productionUnitId={productionUnitId}
            disabled={saving}
          />
        </>
      )}
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default ProductionLineDialog;
