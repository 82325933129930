import React, { FC, forwardRef, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, AssetPost, AssetPut } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import AssetFormCard from './AssetFormCard';
import SiteSelect from '../components/Select/SiteSelect';

type Props = FormProps<AssetPut | AssetPost> & {
  mode: 'update' | 'create';
  asset: Partial<Asset>;
};

const AssetLocationForm: FC<Props> = forwardRef(({ onChange, disabled, asset, mode }, ref) => {
  const { t } = useTranslation('i18n');

  const [errors, setErrors] = useState<Record<string, string>>({});

  useImperativeHandle(ref, () => ({
    isValid() {
      const errors: Record<string, string> = {};
      if (!asset.siteId) {
        errors.siteId = t('form.error.required');
      }
      setErrors(errors);
      return !Object.keys(errors).length;
    }
  }));

  const disableComp = disabled;

  const cleanError = (field: string) => {
    const { [field]: fieldError, ...otherErrors } = errors;
    if (fieldError) {
      //Clean the error
      setErrors(otherErrors);
    }
  };

  const handleSiteChanged = (siteId?: string) => {
    onChange({ ...asset, siteId: siteId });
    cleanError('siteId');
  };
  return (
    <AssetFormCard title={t('asset.sections.location')}>
      <Container>
        {!!asset.isAssigned && <Alert variant="info">{t('asset.locationReadOnly')}</Alert>}
        <Row key={`siteId`}>
          <Col sm={4} className={formStyles.field}>
            {t(`asset.site`)}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <SiteSelect
              value={asset.siteId}
              onChange={handleSiteChanged}
              disabled={disableComp || !!asset.isAssigned}
              isInvalid={!!errors.siteId}
            />

            <Form.Control.Feedback type="invalid">{errors.siteId}</Form.Control.Feedback>
          </Col>
        </Row>
      </Container>
    </AssetFormCard>
  );
});

export default AssetLocationForm;
