import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LoadingComponent.module.scss';
import Spinner from 'react-bootstrap/Spinner';

type Props = {
  label?: string | null;
  size: 'sm' | 'lg';
};

const LoadingComponent: FC<Props> = ({ label, size }) => {
  const { t } = useTranslation('i18n');

  return (
    <div className={`${styles.root}`}>
      <Spinner size={size === 'sm' ? 'sm' : undefined} animation="border" role="status"></Spinner>
      <div className={`${styles.label} ${styles[size]}`}>{label || `${t('status.loading')}...`}</div>
    </div>
  );
};

export default LoadingComponent;
