import React, { FC, useState, useEffect } from 'react';

import CreatableSelect from 'react-select/creatable';

import { Form } from 'react-bootstrap';
import styles from '../form/form.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?: boolean;
  isInvalid?: boolean;
  onChange: (values?: number[]) => void;
  values?: number[];
  options: number[];
  isClearable?: boolean;
};

type Option = {
  value: number;
  label: string;
};

const CreatableBasicNumberSelect: FC<Props> = ({
  onChange,
  values,
  disabled,
  isInvalid,
  options,
  isClearable
}) => {
  const { t } = useTranslation('i18n');
  const [optionItems, setOptionItems] = useState(
    options.map((option) => ({
      value: option,
      label: `${option}`
    }))
  );
  const [selected, setSelected] = useState<Option[]>();

  useEffect(() => {
    setSelected(!values ? [] : optionItems.filter((option) => values.some((v) => option.value === v)));
  }, [values, optionItems]);

  const handleOnChange = (items?: Option[]) => {
    onChange(items ? items.map((i) => i.value) : []);
  };
  const handleOnCreateOption = (option: string) => {
    const value = Number.parseInt(option);
    if (!isNaN(value)) {
      setOptionItems([
        ...optionItems,
        {
          value: value,
          label: `${value}`
        }
      ]);
      onChange([...(values || []), value]);
    }
  };

  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <CreatableSelect
        onCreateOption={handleOnCreateOption}
        className={isInvalid ? styles.invalid : styles.valid}
        options={optionItems}
        onChange={handleOnChange as any}
        isMulti={true}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
        isClearable={isClearable}
        formatCreateLabel={(inputValue) => t('action.addValue', { value: inputValue })}
      />
    </>
  );
};

export default CreatableBasicNumberSelect;
