import React, { FC, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, AssetPost, AssetPut, Characteristic } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import AssetFormCard from './AssetFormCard';

type FieldType = 'brand' | 'model' | 'serial';

const FIELDS: FieldType[] = ['brand', 'model', 'serial'];
const REQUIRED_FIELDS: FieldType[] = ['brand', 'model'];

const isRequired = (field: string) => REQUIRED_FIELDS.some((f) => f === field);

type Props = FormProps<AssetPut | AssetPost> & {
  mode: 'update' | 'create';
  asset: Partial<Asset>;
};

const AssetCharacteristicForm: FC<Props> = forwardRef(({ onChange, disabled, asset, mode }, ref) => {
  const { t } = useTranslation('i18n');

  const [characteristic, setCharacteristic] = useState<Characteristic>({});

  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setCharacteristic(asset?.characteristic || {});
  }, [asset]);

  useImperativeHandle(ref, () => ({
    isValid() {
      const errors = FIELDS.reduce((acc, field) => {
        if (!characteristic[field] && isRequired(field)) {
          return { ...acc, [field]: t('form.error.required') };
        }
        return acc;
      }, {} as Record<string, string>);

      setErrors(errors);
      return !Object.keys(errors).length;
    }
  }));

  const disableComp = disabled;

  const cleanError = (field: string) => {
    const { [field]: fieldError, ...otherErrors } = errors;
    if (fieldError) {
      //Clean the error
      setErrors(otherErrors);
    }
  };

  const handleFieldChanged = (field: FieldType, value?: string) => {
    const data = { ...characteristic, [field]: value };
    setCharacteristic(data);
    onChange({ ...asset, characteristic: data });
    cleanError(field);
  };
  return (
    <AssetFormCard title={t('asset.sections.characteristic')}>
      <Container>
        {FIELDS.map((field) => (
          <Row key={`characteristic_${field}`}>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.${field}`)}

              {isRequired(field) && <span className={formStyles.required}>*</span>}
            </Col>
            <Col sm={8} className={formStyles.input}>
              <Form.Control
                id={field}
                required
                type="text"
                disabled={disableComp}
                value={characteristic[field]}
                isInvalid={!!errors[field]}
                onChange={(event) => handleFieldChanged(field, event.target.value)}
              />

              <Form.Control.Feedback type="invalid">{errors[field]}</Form.Control.Feedback>
            </Col>
          </Row>
        ))}
      </Container>
    </AssetFormCard>
  );
});

export default AssetCharacteristicForm;
