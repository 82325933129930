import React, { FC } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import styles from './Navigation.module.scss';
export type SideMenuRouteProps = {
    name: string;
    path: string;
    Icon: any;
}
const SideMenuRoute: FC<SideMenuRouteProps> = ({
    name, path, Icon
}) => {
    const navigate = useNavigate();
    const goTo = () => {
        navigate(path);
    };
    return (
        <ListItemButton onClick={goTo}>
            <ListItemIcon className={styles.itemButton}>
                <Icon>{Icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={name} />
        </ListItemButton>
    );
};
export default SideMenuRoute;
