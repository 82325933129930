import { Api } from '@memnon.orka/orka-sdk';
import { Auth } from 'aws-amplify';

class CustomApi extends Api<any> {
  roleId: string | undefined | null = window.localStorage.getItem('roleId');

  constructor() {
    super(
      {
        baseUrl: process.env.REACT_APP_API,
        securityWorker: async () => {
          const {
            accessToken: { jwtToken },
          } = (await Auth.currentSession()) as any;

          const headers: Record<string, string> = { Authorization: jwtToken }

          if (this.roleId) {
            headers["Role-Id"] = this.roleId
          }

          return {
            headers,
          };
        },
      }
    )
  }

  async getMyAccess() {
    try {
      const access = await api.myAccess.getMyAccess();
      return access
    } catch (error) {
      //If there is a seleted role then remove it and retry
      if (this.roleId) {
        this.setRoleId(null);
        const access = await api.myAccess.getMyAccess();
        return access
      }
      throw error;
    }
  }

  setRoleId(roleId: string | null) {
    if (roleId) {
      window.localStorage.setItem('roleId', roleId);
    } else {
      window.localStorage.removeItem('roleId');
    }
    this.roleId = roleId
  }
}

const api = new CustomApi();
export default api;
