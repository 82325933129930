import React, { FC } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SideMenuRoute, { SideMenuRouteProps } from './SideMenuRoute';
import styles from './Navigation.module.scss';

type SideMenuSubListProps = {
  title: string;
  paths: SideMenuRouteProps[];
  icon: JSX.Element;
};
const SideMenuSubList: FC<SideMenuSubListProps> = ({ title, paths, icon }) => {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon className={styles.icon}>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {paths.map((route) => {
            return (
              <SideMenuRoute
                key={route.name}
                name={route.name}
                path={route.path}
                Icon={() => (
                  <div className={styles.icon}>
                    <route.Icon />
                  </div>
                )}
              />
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default SideMenuSubList;
