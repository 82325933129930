/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import styles from './Header.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { changeLanguage, getAvailableLanguages } from '../i18n';
import AppStateContext from '../context/AppStateContext';
import { SET_LANGUAGE } from '../context/AppStateReducer';
import SwitchRole from '../components/Access/SwitchRole';

const Header = () => {
  const { t } = useTranslation('i18n');
  const { dispatch, state } = useContext(AppStateContext);

  const handleLogout = async (event: any) => {
    event.preventDefault();
    await Auth.signOut();
  };

  const handleChangeLanguage = async (event: any, lang: string) => {
    event.preventDefault();
    changeLanguage(lang);
    dispatch({ type: SET_LANGUAGE, payload: lang });
  };

  const [languages] = useState(getAvailableLanguages());

  return (
    <>
      <Navbar bg="light" expand="lg" fixed="top" className={styles.navBar} style={{ zIndex: 1400 }}>
        <div className={styles.root}>
          <Navbar.Brand className={styles.icon} href="">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Memnon" />
          </Navbar.Brand>
          <Navbar.Text>
            <h1 className={styles.title}>{t('title')}</h1>
          </Navbar.Text>
          <Navbar>
            <ButtonToolbar className={styles.btnContainer} aria-label="Toolbar with button groups">
              <SwitchRole className={styles.btnGroup} />

              <ButtonGroup className={styles.btnGroup}>
                {languages.map((lang) => (
                  <Button
                    key={lang}
                    disabled={state.language === lang}
                    variant="secondary"
                    className={styles.language}
                    onClick={(e) => handleChangeLanguage(e, lang)}
                  >
                    {lang}
                  </Button>
                ))}
              </ButtonGroup>

              <Button className={styles.btnGroup} onClick={handleLogout}>
                {t('action.logout')}
              </Button>
            </ButtonToolbar>
          </Navbar>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
