import React from 'react';
import { useTranslation } from 'react-i18next';
import DataGrid from '../datagrid/DataGrid';
import api from '../api/Api';
import { Member } from '@memnon.orka/orka-sdk';
import { BodyApi, DataGridColumn } from '../@types/webapp';
import { Resource } from '../tools/resourceTools';
import ResourceSelectFilter from '../components/Select/ResourceSelectFilter';
import { convertApiResponseToSelectOptions } from '../components/Select/selectTools';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_TEXT_DELAY } from '../datagrid/datagridTools';
import { Link } from 'react-router-dom';
import { getMemberPath } from '../navigation/AppRoutes';
import gridStyles from '../datagrid/DataGrid.module.scss';
import { Badge, Button } from 'react-bootstrap';
import AbilityContext, { Can } from '../context/AbilityContext';
import { useAbility } from '@casl/react';
import Country from '../components/Country/Country';
import CountryFilter from '../components/Select/CountryFilter';

const MemberList = () => {
  const { t } = useTranslation('i18n');
  const location = useLocation();
  const navigate = useNavigate();
  const ability = useAbility(AbilityContext);

  const columns: DataGridColumn[] = [
    {
      name: 'lastName',
      header: t('member.lastName'),
      filterProps: {
        type: 'text'
      },
      render: ({ data }: any) => {
        return <Link to={getMemberPath({ mode: 'update', memberId: data.id })}>{data.lastName}</Link>;
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'firstName',
      header: t('member.firstName'),
      filterProps: {
        type: 'text'
      },
      render: ({ data }: any) => {
        return <Link to={getMemberPath({ mode: 'update', memberId: data.id })}>{data.firstName}</Link>;
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'email',
      header: t('member.email'),
      filterProps: {
        type: 'text'
      },
      filterDelay: DEFAULT_TEXT_DELAY
    },
    {
      name: 'country',
      header: t('member.country'),
      render: ({ data }: any) => {
        return <Country countryCode={data.country} />;
      },
      filterProps: {
        type: 'select',
        name: 'country',
        value: location.state?.country,
        _source: location.state?.country
      },
      filterEditor: CountryFilter,
      filterEditorProps: {
        isMulti: true
      },

      filterDelay: DEFAULT_TEXT_DELAY,
      defaultWidth: 200
    },
    {
      name: 'roles',
      header: t('member.roles'),
      sortable: false,
      render: ({ data: { roles } }: { data: Member }) => {
        return (
          <div className={gridStyles.badges}>
            {roles?.length ? (
              (roles || []).map((role) => (
                <Badge bg="primary" key={role.id}>
                  {role.name}
                </Badge>
              ))
            ) : (
              <Badge key="empty" bg="secondary">
                {t('role.empty')}
              </Badge>
            )}
          </div>
        );
      },
      filterProps: {
        type: 'select',
        name: 'roles.id',
        value: location.state?.role?.id,
        _source: location.state?.role
          ? { value: location.state.role.id, label: location.state.role.name }
          : undefined
      },
      filterEditor: ResourceSelectFilter,
      filterEditorProps: {
        isMulti: true,
        loadData: async (search?: string) =>
          convertApiResponseToSelectOptions({
            response: await api.roles.listRoles(
              search
                ? {
                    size: 200,
                    name: `contains:${search}`
                  }
                : {
                    size: 200
                  }
            ),
            idPath: 'id',
            labelPath: 'name'
          })
      },
      defaultFlex: 1,
      minWidth: 100
    }
  ];

  const loadData = async (body: BodyApi) => {
    return api.members.listMembers(body);
  };

  return (
    <>
      <DataGrid
        title={t(`member.title`)}
        columns={columns}
        loadData={loadData}
        resourceName={Resource.MEMBER}
        RightActions={[
          <Can I="post" a={Resource.MEMBER} ability={ability} key="createButton">
            <Button
              key="create"
              onClick={() => {
                navigate(getMemberPath({ mode: 'create' }));
              }}
            >
              {t('member.create')}
            </Button>
          </Can>
        ]}
      />
      <Outlet></Outlet>
    </>
  );
};

export default MemberList;
