import React, { FC, useState, useEffect, useContext } from 'react';
import AppStateContext from '../../context/AppStateContext';

import { getName } from 'i18n-iso-countries';

type Props = { countryCode: string; className?: string };

const Country: FC<Props> = ({ countryCode, className }) => {
  const { state } = useContext(AppStateContext);

  const [country, setCountry] = useState(getName(countryCode, state.language));

  useEffect(() => {
    setCountry(getName(countryCode, state.language));
  }, [countryCode, state.language]);

  return <div className={className}>{country}</div>;
};

export default Country;
