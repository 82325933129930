import React, { FC } from 'react';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import styles from './AssetAssociatedLabel.module.scss';
import { useTranslation } from 'react-i18next';

type Props = { associated: boolean; className?: string };

const AssetAssociatedLabel: FC<Props> = ({ associated, className }) => {
  const { t } = useTranslation('i18n');
  return (
    <div className={`${styles.container} ${className || ''}`}>
      {associated ? <LockIcon className={styles.icon} /> : <LockOpenIcon className={styles.icon} />}
      <div className={styles.associated}>{t(`asset.${associated ? 'associated' : 'notAssociated'}`)}</div>
    </div>
  );
};

export default AssetAssociatedLabel;
