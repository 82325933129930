import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';
import styles from '../../form/form.module.scss';
import { convertApiResponseToSelectOptions } from './selectTools';
import api from '../../api/Api';

type Props = {
  onChange: (value?: string) => void;
  value?: string;
  disabled?: boolean;
  isInvalid?: boolean;
};

type Option = {
  value: string;
  label: string;
};

const SiteSelect: FC<Props> = ({ onChange, value, disabled, isInvalid }) => {
  const { t } = useTranslation('i18n');
  const [selected, setSelected] = useState<Option | Option[]>();

  useEffect(() => {
    setSelected(!value ? undefined : { value: value, label: value });
  }, [value]);

  const handleOnChange = (items?: Option) => {
    onChange(items ? items.value : undefined);
  };

  const loadData = async (search?: string) => {
    const response = await convertApiResponseToSelectOptions({
      response: await api.sites.listSites(
        search
          ? {
              size: 200,
              search: `contains:${search}`
            }
          : {
              size: 200
            }
      ),
      idPath: 'id',
      labelPath: 'id'
    });
    return response;
  };
  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <AsyncSelect
        defaultOptions
        loadOptions={loadData}
        className={isInvalid ? styles.invalid : styles.valid}
        onChange={handleOnChange as any}
        isMulti={false}
        value={selected}
        isDisabled={disabled}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
      />
    </>
  );
};

export default SiteSelect;
