import React, { FC, useState, useContext, useEffect } from 'react';
import { Asset, AssetRef } from '@memnon.orka/orka-sdk';

import { Card, Button, Container, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import styles from './ProductionUnitDetails.module.scss';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AssetTypeLabel from './AssetTypeLabel';
import { Resource } from '../../tools/resourceTools';
import AppStateContext from '../../context/AppStateContext';
import { getErrorKey } from '../../tools/errorTools';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import LoadingComponent from '../Loading/LoadingComponent';
import OrkaLink from '../Link/OrkaLink';
import { getAssetPath } from '../../navigation/AppRoutes';

type Props = {
  assetRef: AssetRef | undefined;
  type: string;
  className?: string;
  handleUpdateClicked?: () => void;
};

const AssetAssociated: FC<Props> = ({ assetRef, type, className, handleUpdateClicked }) => {
  const { t } = useTranslation('i18n');
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AppStateContext);
  const [asset, setAsset] = useState<Asset>();

  const handleUpdateDetails = (event: any) => {
    event.preventDefault();
    if (handleUpdateClicked) {
      handleUpdateClicked();
    }
  };

  const loadAsset = async (assetId: string) => {
    setLoading(true);
    try {
      const response = await api.assets.getAsset(assetId);
      setAsset(response.data);
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!assetRef) {
      setAsset(undefined);
      return;
    }

    let assetMemory = state.resources[Resource.ASSET]?.items?.find((i) => i.id === assetRef.id) as
      | Asset
      | undefined;
    if (assetMemory) {
      setAsset(assetMemory);
    } else if (assetRef && assetRef.id !== asset?.id) {
      loadAsset(assetRef.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetRef, state.resources]);

  return (
    <div className={className}>
      <Card>
        <Card.Header className={styles.header}>
          <div className={styles.headerTitle}>
            <AssetTypeLabel type={type} />
          </div>
          <div className={styles.headerBtn}>
            {handleUpdateClicked && (
              <Button variant="outline-secondary" size="sm" onClick={handleUpdateDetails}>
                <ModeEditIcon />
              </Button>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <LoadingComponent size="sm" />
          ) : asset ? (
            <Container className={styles.container}>
              <Row className={styles.row}>
                <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={`${styles.field}`}>
                  {t('asset.reference.productionId')}
                </Col>
                <Col fluid className={`${styles.value} ${styles.main}`}>
                  <OrkaLink
                    url={getAssetPath({ mode: 'update', assetId: asset.id })}
                    label={asset.reference?.productionId}
                    openNewPage
                  />
                </Col>
              </Row>
              {(assetRef as any)?.port !== undefined && (
                <Row className={styles.row}>
                  <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                    {t('asset.port')}
                  </Col>
                  <Col fluid className={styles.value}>
                    {(assetRef as any).port}
                  </Col>
                </Row>
              )}
              {asset.comments && (
                <Row className={styles.row}>
                  <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                    {t('asset.comments')}
                  </Col>
                  <Col fluid className={styles.value}>
                    {asset.comments}
                  </Col>
                </Row>
              )}
              <Row className={styles.row}>
                <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                  {t('asset.brand')}
                </Col>
                <Col fluid className={styles.value}>
                  {asset.characteristic?.brand}
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                  {t('asset.model')}
                </Col>
                <Col fluid className={styles.value}>
                  {asset.characteristic?.model}
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                  {t('asset.serial')}
                </Col>
                <Col fluid className={styles.value}>
                  {asset.characteristic?.serial}
                </Col>
              </Row>
            </Container>
          ) : (
            <Container className={styles.container}>
              <Row className={styles.row}>
                <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}></Col>
                <Col fluid className={styles.value}>
                  {t('common.empty')}
                </Col>
              </Row>
            </Container>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default AssetAssociated;
