import React, { FC, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset, AssetPost, AssetPut, Reference } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form, Dropdown, Button, InputGroup } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AssetFormCard from './AssetFormCard';

const FIELDS: string[] = ['productionId', 'inventoryId', 'custom1', 'custom2', 'custom3'];
const REQUIRED_FIELDS: string[] = ['productionId'];

type Props = FormProps<AssetPut | AssetPost> & {
  mode: 'update' | 'create';
  asset: Partial<Asset>;
};

const AssetCharacteristicForm: FC<Props> = forwardRef(({ onChange, disabled, asset, mode }, ref) => {
  const { t } = useTranslation('i18n');

  const [reference, setReference] = useState<Reference>({});
  const [availableFields, setAvailableFields] = useState<string[]>(FIELDS);

  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    const defaultReference = REQUIRED_FIELDS.reduce(
      (acc, field) => ({ ...acc, [field]: '' }),
      {} as Reference
    );

    const reference: Reference = Object.keys(asset?.reference || {}).reduce(
      (acc, field) => ({ ...acc, [field]: asset?.reference?.[field] || '' }),
      defaultReference
    );
    const usedFields = Object.keys(reference);
    setAvailableFields(FIELDS.filter((f) => !usedFields.some((u) => u === f)));

    setReference(reference);
  }, [asset?.reference]);

  useImperativeHandle(ref, () => ({
    isValid() {
      const errors = REQUIRED_FIELDS.reduce((acc, field) => {
        if (!reference[field]) {
          return { ...acc, [field]: t('form.error.required') };
        }
        return acc;
      }, {} as Record<string, string>);

      setErrors(errors);
      return !Object.keys(errors).length;
    }
  }));

  const disableComp = disabled;

  const cleanError = (field: string) => {
    const { [field]: fieldError, ...otherErrors } = errors;
    if (fieldError) {
      //Clean the error
      setErrors(otherErrors);
    }
  };

  const handleFieldChanged = (field: string, value?: string) => {
    const data = { ...reference, [field]: value };
    setReference(data);
    onChange({ ...asset, reference: data });
    cleanError(field);
  };

  const handleAddField = (field: string) => {
    const data = { ...reference, [field]: '' };
    setReference(data);
    onChange({ ...asset, reference: data });
    cleanError(field);
  };

  const handleRemoveField = (field: string) => {
    const data = { ...reference };
    delete data[field];
    setReference(data);
    onChange({ ...asset, reference: data });
    cleanError(field);
  };

  return (
    <AssetFormCard
      title={t('asset.sections.reference')}
      toolbar={
        <Dropdown>
          <Dropdown.Toggle
            size="sm"
            disabled={!availableFields.length || disableComp}
            variant="secondary"
            id="reference-fields"
          >
            <AddIcon className={formStyles.buttonIcon} />
            <span className={formStyles.buttonLabel}>{t('action.add')}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {availableFields.map((field) => (
              <Dropdown.Item key={`reference-${field}`} onClick={() => handleAddField(field)}>
                {t(`asset.reference.${field}`)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      }
    >
      <Container>
        {Object.keys(reference).map((field) => {
          const required = REQUIRED_FIELDS.some((f) => f === field);
          return (
            <Row key={`reference_${field}`}>
              <Col sm={4} className={formStyles.field}>
                {t(`asset.reference.${field}`)}
                {required && <span className={formStyles.required}>*</span>}
              </Col>
              <Col sm={8} className={formStyles.input}>
                <InputGroup>
                  <Form.Control
                    id={field}
                    required
                    type="text"
                    disabled={disableComp}
                    value={reference[field]}
                    isInvalid={!!errors[field]}
                    onChange={(event) => handleFieldChanged(field, event.target.value)}
                  />

                  {!required && (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      disabled={required}
                      onClick={() => handleRemoveField(field)}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </InputGroup>

                <Form.Control.Feedback type="invalid">{errors[field]}</Form.Control.Feedback>
              </Col>
            </Row>
          );
        })}
      </Container>
    </AssetFormCard>
  );
});

export default AssetCharacteristicForm;
