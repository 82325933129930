import React, { FC, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../../dialog/DefaultCrudDialog';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getProductionUnitPath } from '../../navigation/AppRoutes';
import { Asset, AssetRef, ProductionUnit, RemoteConfiguration } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../../tools/errorTools';
import AppStateContext from '../../context/AppStateContext';
import { DispatchResourceAction, UPDATE_RESOURCE } from '../../context/AppStateReducer';
import { Resource } from '../../tools/resourceTools';
import api from '../../api/Api';
import AssetSelect from '../Select/AssetSelect';
import { Col, Container, Form, Row } from 'react-bootstrap';
import formStyles from '../../form/form.module.scss';
import { getAssetTypeLabel } from '../../tools/assetTools';

type Props = {};

const ProductionUnitAssetAssociationDialog: FC<Props> = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();

  const { productionUnitId, module } = useParams() as {
    productionUnitId: string;
    module: 'workStation' | 'robot';
  };

  const [searchParams] = useSearchParams();
  const siteId = searchParams.get('siteId') || undefined;

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [productionUnit, setProductionUnit] = useState<ProductionUnit>();
  const [asset, setAsset] = useState<Asset>();
  const [defaultAsset, setDefaultAsset] = useState<Asset>();
  const [port, setPort] = useState<string>();
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [loading, setLoading] = useState(false);

  const handleLoadProductionUnit = async (productionUnitId: string) => {
    setLoading(true);

    try {
      const { data: productionUnit } = await api.production.getProductionUnit(productionUnitId);
      setProductionUnit(productionUnit);
      const assetRef = productionUnit[module];
      setPort(`${(assetRef as RemoteConfiguration)?.port}`);
      if (assetRef) {
        const asset = await api.assets.getAsset(assetRef.id);
        setAsset(asset.data);
        setDefaultAsset(asset.data);
      } else {
        setAsset(undefined);
        setDefaultAsset(undefined);
      }
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (productionUnitId) {
      handleLoadProductionUnit(productionUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionUnitId]);

  const handleClose = () => {
    setShowModal(false);
    navigate(getProductionUnitPath({ mode: 'get', productionUnitId }));
  };

  const handleSave = async () => {
    const errors: Record<string, string> = {};
    const body: (Partial<AssetRef> & Partial<RemoteConfiguration>) | null = asset || null;

    if (body && module === 'robot') {
      body.port = !port ? undefined : Number.parseInt(port);
      if (!body.port && body.port !== 0) {
        errors.port = t('form.error.required');
      } else if (isNaN(body.port)) {
        errors.port = t('form.error.not-number');
      }
    }
    if (Object.values(errors).length) {
      setErrors(errors);
      return false;
    }

    setSaving(true);
    try {
      const { data: productionUnitUpdated } = await api.production.updateProductionUnit(productionUnitId, {
        [module]: body
      });
      dispatch({
        payload: productionUnitUpdated,
        resourceName: Resource.PRODUCTION_UNIT,
        type: UPDATE_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`production-unit.success.update`, { name: productionUnitUpdated.name }));
      setSaving(false);
      return true;
    } catch (error: any) {
      const notAssociableError = error?.error?.error?.details?.find((d: any) => d.type === 'not-associable');
      let message = t(getErrorKey(error));
      if (notAssociableError) {
        message = t(`asset.error.not-associable`, {
          name: asset?.reference?.productionId || '-',
          productionUnit: notAssociableError?.context?.productionUnit?.name || '-'
        });
      }

      toast.error(message, {
        autoClose: false
      });

      setSaving(false);
      return false;
    }
  };

  const handleAssetChanged = (asset: Asset | undefined) => {
    setAsset(asset);
  };
  const handlePortChanged = (value: string | undefined) => {
    setPort(value);
    setErrors({ ...errors, port: undefined });
  };

  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: 'post',
        resource: Resource.SITE
      }}
      title={t(`production-unit.associate.${module}`)}
      closeLabel={t('action.cancel')}
      saveLabel={
        asset ? t(`production-unit.saveAsset.${module}`) : t(`production-unit.removeAsset.${module}`)
      }
      onClose={handleClose}
      saving={saving}
      onSave={handleSave}
      loading={loading}
    >
      <Container>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {getAssetTypeLabel(module)}
          </Col>
          <Col sm={8} className={formStyles.input}>
            <AssetSelect
              acceptedTypes={[module]}
              parentId={productionUnit?.id}
              parentType="ProductionUnit"
              defaultValue={defaultAsset}
              value={asset}
              siteId={siteId}
              onChange={handleAssetChanged}
              isClearable
              showAssociated={showAll}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}></Col>
          <Col sm={8} className={formStyles.input}>
            <Form.Check
              className={formStyles.checkbox}
              id="showAll"
              label={t(`production-unit.showAll.${module}`)}
              checked={showAll}
              onChange={() => setShowAll(!showAll)}
            />
          </Col>
        </Row>
        {module === 'robot' && asset && (
          <Row>
            <Col sm={4} className={formStyles.field}>
              {t(`asset.port`)} <span className={formStyles.required}>*</span>
            </Col>
            <Col sm={8} className={formStyles.input}>
              <Form.Control
                id="name"
                required
                type="number"
                value={port}
                isInvalid={!!errors.port}
                onChange={(event) => handlePortChanged(event.target.value)}
              />

              <Form.Control.Feedback type="invalid">{errors.port}</Form.Control.Feedback>
            </Col>
          </Row>
        )}
      </Container>
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default ProductionUnitAssetAssociationDialog;
