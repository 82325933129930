import React, { FC, useState, useEffect } from 'react';
import styles from './TypeLabel.module.scss';

import VideoIcon from '@mui/icons-material/Movie';
import FilmIcon from '@mui/icons-material/Theaters';
import AudioIcon from '@mui/icons-material/MusicVideo';
import { useTranslation } from 'react-i18next';

type Props = { type: string; className?: string };

const TypeLabel: FC<Props> = ({ type, className }) => {
  const [Icon, setIcon] = useState<JSX.Element>();
  const { t } = useTranslation('i18n');

  useEffect(() => {
    if (type === 'video') {
      setIcon(<VideoIcon className={styles.icon} />);
    } else if (type === 'audio') {
      setIcon(<AudioIcon className={styles.icon} />);
    } else {
      setIcon(<FilmIcon className={styles.icon} />);
    }
  }, [type]);

  return (
    <div className={`${styles.container} ${className || ''}`}>
      {Icon}
      <div className={styles.type}>{t(`production-unit.typeValues.${type}`)}</div>
    </div>
  );
};

export default TypeLabel;
