/* eslint react-hooks/exhaustive-deps: 0 */
import React, { FC } from 'react';
import { AppStateCtx } from '../@types/webapp';
import AppStateContext from './AppStateContext';

type Props = {
  value: AppStateCtx;
};

const AppStateContextProvider: FC<Props> = ({ value, children }) => {
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateContextProvider;
