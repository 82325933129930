import React, { FC, useState, useEffect } from 'react';

import Select from 'react-select';

import { Form } from 'react-bootstrap';
import styles from '../form/form.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?: boolean;
  isInvalid?: boolean;
  onChange: (value: string[]) => void;
  values?: string[];
  options: string[];
  isClearable?: boolean;
};

type Option = {
  value: string;
  label: string;
};

const BasicMultiSelect: FC<Props> = ({ onChange, values, disabled, isInvalid, options, isClearable }) => {
  const { t } = useTranslation('i18n');
  const [optionItems] = useState(
    options.map((option) => ({
      value: option,
      label: option
    }))
  );
  const [selected, setSelected] = useState<Option[]>();

  useEffect(() => {
    setSelected(!values ? [] : optionItems.filter((option) => values.some((v) => option.value === v)));
  }, [values, optionItems]);

  const handleOnChange = (items?: Option[]) => {
    onChange(items ? items.map((i) => i.value) : []);
  };

  return (
    <>
      <Form.Control type="hidden" isInvalid={isInvalid}></Form.Control>
      <Select
        className={isInvalid ? styles.invalid : styles.valid}
        options={optionItems}
        onChange={handleOnChange as any}
        isMulti
        value={selected}
        isDisabled={disabled}
        isClearable={isClearable}
        noOptionsMessage={() => t('common.noValue')}
        placeholder=""
      />
    </>
  );
};

export default BasicMultiSelect;
