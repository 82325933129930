import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultCrudDialog from '../dialog/DefaultCrudDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductionUnitPath } from '../navigation/AppRoutes';
import { ProductionUnit, ProductionUnitPost, ProductionUnitPut } from '@memnon.orka/orka-sdk';

import { toast } from 'react-toastify';
import { getErrorKey } from '../tools/errorTools';
import AppStateContext from '../context/AppStateContext';
import { ADD_RESOURCE, DispatchResourceAction, UPDATE_RESOURCE } from '../context/AppStateReducer';
import { Resource } from '../tools/resourceTools';
import api from '../api/Api';
import ProductionUnitForm from './ProductionUnitForm';

const ProductionUnitDialog = () => {
  const { dispatch } = useContext(AppStateContext);
  const { t } = useTranslation('i18n');
  const navigate = useNavigate();

  const ref = useRef();

  const { productionUnitId } = useParams();

  const [showModal, setShowModal] = useState(true);
  const [saving, setSaving] = useState(false);
  const [existingProductionUnit, setExistingProductionUnit] = useState<ProductionUnit>();
  const [updatedProductionUnit, setUpdatedProductionUnit] = useState<Partial<ProductionUnit>>();
  const [loading, setLoading] = useState(false);

  const handleLoadProductionUnit = async (productionUnitId: string) => {
    setLoading(true);

    try {
      const { data } = await api.production.getProductionUnit(productionUnitId);
      setExistingProductionUnit(data);
    } catch (error) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (productionUnitId) {
      handleLoadProductionUnit(productionUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productionUnitId]);

  const handleClose = () => {
    setShowModal(false);
    if (productionUnitId) {
      navigate(getProductionUnitPath({ mode: 'get', productionUnitId }));
    } else {
      navigate(getProductionUnitPath({ mode: 'list' }));
    }
  };

  const handleCreate = async (productionUnit: ProductionUnitPost) => {
    try {
      const { data: productionUnitCreated } = await api.production.createProductionUnit(
        productionUnit as ProductionUnitPost
      );
      dispatch({
        payload: productionUnitCreated,
        resourceName: Resource.PRODUCTION_UNIT,
        type: ADD_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`production-unit.success.create`, { name: productionUnitCreated.name }));
      return productionUnitCreated;
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });

      return undefined;
    }
  };

  const handleUpdate = async (productionUnitId: string, productionUnit: ProductionUnitPut) => {
    try {
      const { data: productionUnitUpdated } = await api.production.updateProductionUnit(productionUnitId, {
        name: productionUnit.name
      });
      dispatch({
        payload: productionUnitUpdated,
        resourceName: Resource.PRODUCTION_UNIT,
        type: UPDATE_RESOURCE
      } as DispatchResourceAction);
      toast.success(t(`production-unit.success.update`, { name: productionUnitUpdated.name }));
      return productionUnitUpdated;
    } catch (error: any) {
      toast.error(t(getErrorKey(error)), {
        autoClose: false
      });
      return undefined;
    }
  };

  const handleSave = async () => {
    if (!(ref?.current as any)?.isValid()) {
      return false;
    }

    setSaving(true);
    let response: ProductionUnit | undefined;
    if (productionUnitId) {
      response = await handleUpdate(productionUnitId, updatedProductionUnit as ProductionUnitPut);
    } else {
      response = await handleCreate(updatedProductionUnit as ProductionUnitPost);
    }
    setSaving(false);

    if (response) {
      setShowModal(false);
      navigate(getProductionUnitPath({ mode: 'get', productionUnitId: response.id }));
    }
    //Do not call onClose from parent
    return false;
  };

  return showModal ? (
    <DefaultCrudDialog
      permissionScope={{
        action: 'post',
        resource: Resource.SITE
      }}
      title={productionUnitId ? t(`production-unit.update`) : t(`production-unit.create`)}
      closeLabel={t('action.cancel')}
      saveLabel={productionUnitId ? t(`action.update`) : t(`action.create`)}
      onClose={handleClose}
      saving={saving}
      onSave={handleSave}
      loading={loading}
    >
      {existingProductionUnit ? (
        <ProductionUnitForm
          ref={ref}
          mode="update"
          productionUnit={existingProductionUnit}
          onChange={setUpdatedProductionUnit}
          disabled={saving}
        />
      ) : (
        <ProductionUnitForm ref={ref} mode="create" onChange={setUpdatedProductionUnit} disabled={saving} />
      )}
    </DefaultCrudDialog>
  ) : (
    <></>
  );
};

export default ProductionUnitDialog;
