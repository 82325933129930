import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Fade } from 'react-bootstrap';
import styles from './DefaultCrudDialog.module.scss';
import { Can, useAbility } from '@casl/react';
import AbilityContext from '../context/AbilityContext';
import LoadingComponent from '../components/Loading/LoadingComponent';
import { confirmWrapper } from '../tools/confirm';

type DialogProps = {
  title: string;
  onSave?: () => boolean | Promise<boolean>;
  saveLabel?: string | null;
  onClose: () => void;
  closeLabel?: string | null;
  onDelete?: () => boolean | Promise<boolean>;
  deleteLabel?: string | null;
  deleteConfirmLabel?: JSX.Element | string | null;

  loading?: boolean;

  permissionScope: {
    resource: string;
    action: string;
  };

  saving?: boolean;
};

const DefaultCrudDialog: FC<DialogProps> = ({
  children,
  title,
  onSave,
  saveLabel,
  onClose,
  closeLabel,
  onDelete,
  deleteLabel,
  deleteConfirmLabel,
  permissionScope,
  saving,
  loading
}) => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const handleSave = async () => {
    let close = true;
    if (onSave) {
      close = await onSave();
    }
    if (close) {
      onClose();
    }
  };

  const handleDelete = async () => {
    if (
      !(await confirmWrapper(deleteConfirmLabel || '', {
        btnPrimaryLabel: deleteLabel || t('action.delete'),
        btnPrimaryVariant: 'danger',
        btnSecondaryLabel: t('action.cancel')
      }))
    ) {
      return;
    }

    let close = true;
    if (onDelete) {
      close = await onDelete();
    }
    if (close) {
      onClose();
    }
  };

  const saveActionLabel = saveLabel || t('action.save');

  return (
    <Modal
      className={styles.modal}
      show
      onHide={onClose}
      size="lg"
      transition={Fade}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading ? (
        <div className={styles.loading}>
          <LoadingComponent size="lg" />
        </div>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{children}</Modal.Body>

          <Modal.Footer className={styles.actions}>
            <div className={styles.leftActions}>
              <Can I="delete" a={permissionScope.resource} ability={ability}>
                {onDelete && (
                  <Button variant="danger" onClick={handleDelete} disabled={saving}>
                    {deleteLabel || t('action.delete')}
                  </Button>
                )}
              </Can>
            </div>
            <div className={styles.rightActions}>
              <Button variant="secondary" onClick={() => onClose()}>
                {closeLabel || t('action.close')}
              </Button>
              <Can I={permissionScope.action} a={permissionScope.resource} ability={ability}>
                {onSave && (
                  <Button variant="primary" onClick={handleSave} disabled={saving}>
                    {saving ? <LoadingComponent size="sm" label={saveActionLabel} /> : saveActionLabel}
                  </Button>
                )}
              </Can>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default DefaultCrudDialog;
