import React, { FC } from 'react';
import styles from './OrkaLink.module.scss';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type Props = {
  url: string;
  size?: 'sm' | 'lg';
  label?: string;
  className?: string;
  openNewPage?: boolean;
};

const OrkaLink: FC<Props> = ({ openNewPage, className, url, label, size }) => {
  return (
    <Link className={className} to={url} target={openNewPage ? '_blank' : undefined}>
      {label}
      <OpenInNewIcon className={size === 'lg' ? styles.linkLargeIcon : styles.linkIcon} />
    </Link>
  );
};

export default OrkaLink;
