import React, { FC, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styles from './OperatedByInput.module.scss';
import { useTranslation } from 'react-i18next';

export const MEMNON = 'Memnon';
const OTHER = 'other';

type Props = {
  disabled?: boolean;
  isInvalid?: boolean;
  onChange: (value: string) => void;
  value: string;
};

const OperatedByInput: FC<Props> = ({ onChange, value, disabled, isInvalid }) => {
  const { t } = useTranslation('i18n');

  const [other, setOther] = useState<string>('');
  const [mode, setMode] = useState<string>();

  useEffect(() => {
    if (value !== undefined && value !== MEMNON) {
      setOther(value);
    }
    setMode(value === undefined || value === MEMNON ? MEMNON : OTHER);
  }, [value]);

  const handleModeChange = (mode: string) => {
    setMode(mode);
    onChange(mode === OTHER ? other : MEMNON);
  };

  const handleOtherChange = (text: string) => {
    setOther(text);
    onChange(text);
  };

  return (
    <div className={styles.root}>
      <div className={styles.selection}>
        <div className={styles.memnonCheck}>
          <Form.Check
            type="radio"
            id={MEMNON}
            name="operatedBy"
            value={MEMNON}
            checked={mode === MEMNON}
            label={MEMNON}
            onChange={() => {}}
            onClick={() => handleModeChange(MEMNON)}
          />
        </div>
        <div className={styles.otherCheck}>
          <Form.Check
            type="radio"
            id={`other`}
            name="operatedBy"
            value={OTHER}
            checked={mode === OTHER}
            label={t('site.other')}
            onChange={() => {}}
            onClick={() => handleModeChange(OTHER)}
          />
        </div>
      </div>
      {mode === 'other' && (
        <div className={styles.other}>
          <Form.Control
            type="text"
            value={other}
            disabled={disabled}
            onFocus={() => setMode(OTHER)}
            isInvalid={isInvalid}
            autoFocus
            onChange={(event) => handleOtherChange(event.target.value)}
          />
        </div>
      )}
    </div>
  );
};

export default OperatedByInput;
