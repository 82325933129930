import React, { FC } from 'react';
import { ProductionUnit } from '@memnon.orka/orka-sdk';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import styles from './ProductionUnitDetails.module.scss';
import SiteLabel from './SiteLabel';
import TypeLabel from './TypeLabel';

import ModeEditIcon from '@mui/icons-material/ModeEdit';

type Props = { productionUnit: ProductionUnit; className?: string; handleUpdateClicked?: () => void };

const ProductionUnitDetails: FC<Props> = ({ productionUnit, handleUpdateClicked, className }) => {
  const { t } = useTranslation('i18n');

  const handleUpdateDetails = (event: any) => {
    event.preventDefault();
    if (handleUpdateClicked) {
      handleUpdateClicked();
    }
  };

  return (
    <div className={className}>
      <Card>
        <Card.Header className={styles.header}>
          <div className={styles.headerTitle}>{t('production-unit.details')}</div>
          {handleUpdateClicked && (
            <div className={styles.headerBtn}>
              <Button variant="outline-secondary" size="sm" onClick={handleUpdateDetails}>
                <ModeEditIcon />
              </Button>
            </div>
          )}
        </Card.Header>
        <Card.Body>
          <Container className={styles.container}>
            <Row className={styles.row}>
              <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                {t('production-unit.name')}
              </Col>
              <Col fluid className={styles.value}>
                {productionUnit.name}
              </Col>
            </Row>
            <Row className={styles.row}>
              <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                {t('production-unit.site')}
              </Col>
              <Col fluid className={styles.value}>
                <SiteLabel siteId={productionUnit.siteId} />
              </Col>
            </Row>
            <Row className={styles.row}>
              <Col xs="6" sm="6" md="5" lg="4" xl="3" xxl="2" className={styles.field}>
                {t('production-unit.type')}
              </Col>
              <Col fluid className={styles.value}>
                <TypeLabel type={productionUnit.type} />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductionUnitDetails;
