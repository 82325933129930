import React, { FC, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductionUnit } from '@memnon.orka/orka-sdk';
import { FormProps } from '../@types/webapp';
import { Container, Row, Col, Form } from 'react-bootstrap';
import formStyles from '../form/form.module.scss';
import ProductionUnitTypeSelect from './ProductionUnitTypeSelect';
import SiteSelect from '../components/Select/SiteSelect';

type UpdateProductionUnitProps = FormProps<ProductionUnit> & {
  mode: 'update';
  productionUnit: ProductionUnit;
};

type CreateProductionUnitProps = FormProps<ProductionUnit> & {
  mode: 'create';
  //Property not relevent for creation but only to simplify the code
  productionUnit?: undefined;
};

const ProductionUnitForm: FC<CreateProductionUnitProps | UpdateProductionUnitProps> = forwardRef(
  ({ onChange, disabled, productionUnit, mode }, ref) => {
    const { t } = useTranslation('i18n');

    const [name, setName] = useState<string>();
    const [siteId, setSiteId] = useState<string>();
    const [type, setType] = useState<string>();

    const [errors, setErrors] = useState<Record<string, string>>({});

    useEffect(() => {
      setName(productionUnit?.name);
      setSiteId(productionUnit?.siteId);
      setType(productionUnit?.type);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productionUnit]);

    useImperativeHandle(ref, () => ({
      isValid() {
        const errors: Record<string, string> = {};
        if (!name) {
          errors.name = t('form.error.required');
        }
        if (!siteId) {
          errors.siteId = t('form.error.required');
        }
        if (!type) {
          errors.type = t('form.error.required');
        }

        setErrors(errors);
        return !Object.keys(errors).length;
      }
    }));

    useEffect(() => {
      if (name || siteId || type) {
        onChange(
          mode === 'create'
            ? {
                name,
                siteId,
                type
              }
            : {
                name
              }
        );
      }
    }, [name, siteId, type, onChange, mode]);

    const disableComp = disabled;

    const cleanError = (field: string) => {
      const { [field]: fieldError, ...otherErrors } = errors;
      if (fieldError) {
        //Clean the error
        setErrors(otherErrors);
      }
    };

    const handleNameChanged = (name?: string) => {
      setName(name);
      cleanError('name');
    };
    const handleSiteIdChanged = (siteId?: string) => {
      setSiteId(siteId);
      cleanError('siteId');
    };
    const handleTypeChanged = (type?: string) => {
      setType(type);
      cleanError('type');
    };

    return (
      <Container>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('production-unit.name')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <Form.Control
              id="name"
              required
              type="text"
              disabled={disableComp}
              value={name}
              isInvalid={!!errors.name}
              onChange={(event) => handleNameChanged(event.target.value)}
            />

            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('production-unit.site')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <SiteSelect
              value={siteId}
              onChange={handleSiteIdChanged}
              disabled={disableComp || !!productionUnit}
              isInvalid={!!errors.siteId}
            />

            <Form.Control.Feedback type="invalid">{errors.siteId}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={formStyles.field}>
            {t('production-unit.type')}
            <span className={formStyles.required}>*</span>
          </Col>
          <Col sm={8} className={formStyles.input}>
            <ProductionUnitTypeSelect
              value={type}
              onChange={handleTypeChanged}
              disabled={disableComp || !!productionUnit}
              isInvalid={!!errors.type}
            />

            <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default ProductionUnitForm;
